@import '../../modules';

$image-width: 100%;
$image-height: 17.5rem;

.shimmer {
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
    aspect-ratio: 16/9;
    background-color: $color-gray-1;

    &Box {
        aspect-ratio: 16/9;
        border-radius: 10px;
        overflow: hidden;
        display: block;
    }
}

.description {
    -webkit-line-clamp: 3;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.loading {
    height: 20px;
}