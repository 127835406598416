@import '../../../modules';

.box {
    background-color: $color-gray-1;
    border-radius: 30px;
    border-bottom: 2px solid;
}

.image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin: 0;
    border-radius: 50%;
    padding: 0.25rem;

    &Wrapper {
        width: 6rem;
        height: 6rem;
        border-radius: 50%;
        flex-shrink: 0;
        overflow: hidden;
        background-color: #fff;
    }
}