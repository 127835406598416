@import '../../modules';

$image-width: 360px;
$image-height: 215px;

$image-width-tablet: 360px;
$image-height-tablet: 215px;

.finished {
    filter: grayscale(0.8);
    mix-blend-mode: luminosity;
}

.cover {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;

    &Box {
        overflow: hidden;
        width: 100%;
        height: $image-height;
        border-radius: 10px;
        flex-shrink: 0;
        margin-bottom: 15px;
        display: block;
        position: relative;

        @include respond-above(xs) {
            margin-right: 20px;
            margin-bottom: 0;
        }
    }

    &Badge {
        position: absolute;
        right: 10px;
        bottom: 10px;
        z-index: 1;
        background-color: #fff;
        box-shadow: 0 3px 6px $box-shadow-color;
    }

}

.badge {
    margin-top: 2px;
}

.meta {
    margin-bottom: 15px;
    margin-top: 5px;

    &Item {
        &:not(:last-child) {
            margin-bottom: 10px;
            line-height: 1.65;
        }
    }
}

.text {
    // overflow: hidden;
    // -webkit-line-clamp: 3;
    // display: -webkit-box;
    // -webkit-box-orient: vertical;
}