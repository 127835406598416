@import '../modules';

$base_size: 1;
$from: 1;
$to: 100;

@for $i from $from through $to {
	.w-#{$i * $base_size} {
		width: $i * $base_size * 1%;
	}
}

@each $grid_key, $grid_value in $breakpoints {
	@include respond-above($grid_key) {
		@for $i from $from through $to {
			.w-#{$grid_key}-#{$i * $base_size} {
				width: $i * $base_size * 1% !important;
			}
		}
		.w-#{$grid_key}-auto {
			width: auto !important;
		}
		.h-#{$grid_key}-100 {
			height: 100%;
		}
	}
}

// @include respond-above(xl) {
// 	@for $i from $from through $to {
// 		.w-xl-#{$i * $base_size} {
// 			width: $i * $base_size * 1% !important;
// 		}
// 	}

// 	.w-xl-auto {
// 		width: auto !important;
// 	}

// 	.h-xl-100 {
// 		height: 100%;
// 	}
// }

// @include respond-above(lg) {
// 	@for $i from $from through $to {
// 		.w-lg-#{$i * $base_size} {
// 			width: $i * $base_size * 1%;
// 		}
// 	}

// 	.w-lg-auto {
// 		width: auto !important;
// 	}

// 	.h-lg-100 {
// 		height: 100%;
// 	}
// }

// @include respond-above(md) {
// 	@for $i from $from through $to {
// 		.w-md-#{$i * $base_size} {
// 			width: $i * $base_size * 1%;
// 		}
// 	}

// 	.w-md-auto {
// 		width: auto !important;
// 	}

// 	.h-md-100 {
// 		height: 100%;
// 	}
// }

// @include respond-above(sm) {
// 	@for $i from $from through $to {
// 		.w-sm-#{$i * $base_size} {
// 			width: $i * $base_size * 1%;
// 		}
// 	}

// 	.w-sm-auto {
// 		width: auto !important;
// 	}

// 	.h-sm-100 {
// 		height: 100%;
// 	}
// }

// @include respond-above(xs) {
// 	@for $i from $from through $to {
// 		.w-xs-#{$i * $base_size} {
// 			width: $i * $base_size * 1%;
// 		}
// 	}

// 	.w-xs-auto {
// 		width: auto !important;
// 	}

// 	.h-xs-100 {
// 		height: 100%;
// 	}
// }
