@import '../../modules';

.template {
	&_grid {
		display: flex;
		gap: 1rem;
		width: 100%;

		@include respond-above(sm) {
			display: grid;
			grid-template-columns: repeat(auto-fill, minmax(165px, 1fr));
			white-space: normal;
		}
	}

	&_scrollbar {
		@include respond-below(sm) {
			overflow-x: auto !important;
			white-space: nowrap;
			height: auto;
			padding-bottom: 1rem;
			margin-bottom: -1rem;
		}

		@include respond-above(sm) {
			padding-right: 1rem;
			margin-right: -1rem;
			padding-bottom: 0;
			margin-bottom: 0;
			max-height: 660px;
		}
	}
}

.asset_list {
	overflow-x: auto;
	white-space: nowrap;
}