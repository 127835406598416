@import '../../modules';

.close {
    width: 40px;
    height: 40px;
    justify-content: flex-end;
    padding: 0 !important;
    flex-shrink: 0;
    color: $color-gray-3;
    margin-left: auto;

    &:hover {
        color: $color-gray-4;
    }
}

.text {
    p {
        line-height: 25px;

        &:last-of-type {
            margin-bottom: 0;
        }
    }
}

.header {
    display: flex;
    align-items: center;
    margin-bottom: $modal-padding;
}