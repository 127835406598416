@import '../../../modules';

.wrapper {
	position: relative;
	padding-top: 7rem;
	padding-bottom: 5rem;
	// margin-bottom: 3rem;
	background: rgb(208, 208, 208);
	background: linear-gradient(
		180deg,
		rgba(208, 208, 208, 1) 7%,
		rgba(250, 250, 250, 1) 62%,
		rgba(255, 255, 255, 1) 98%
	);
}

.decoration {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;

	&:before {
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		height: 100%;
		background: -moz-linear-gradient(
			top,
			rgba(255, 255, 255, 0) 0%,
			rgba(255, 255, 255, 1) 100%
		);
		background: -webkit-linear-gradient(
			top,
			rgba(255, 255, 255, 0) 0%,
			rgba(255, 255, 255, 1) 100%
		);
		background: linear-gradient(
			to bottom,
			rgba(255, 255, 255, 0) 0%,
			rgba(255, 255, 255, 1) 100%
		);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=0);
	}

	&:after {
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		height: 100%;
		opacity: 0.5;
		mix-blend-mode: screen;
	}
}

.background {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;

	&::before {
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		background: linear-gradient(
			to bottom,
			rgba(255, 255, 255, 0) 0%,
			rgba(255, 255, 255, 1) 100%
		);
	}
}

.switch {
    margin-bottom: 1rem;
    padding-top: 1.5rem;
    position: relative;
    z-index: $overlay-z-index + 1;
}

.card {
	height: calc(100% - 22px);
	padding: 0 !important;
	border-radius: 30px;
}

.sidebar {
	&_header {
		min-height: 50px;
		padding-bottom: 1.5rem;
		padding-top: 1.5rem;
		margin: 0 1.5rem 1.5rem;
		border-bottom: 1px solid $color-gray-1;
		display: flex;
		align-items: center;
	}

	&_body {
		margin: 0 1.5rem 1.5rem;
	}

	&_section {
		margin-bottom: 1.5rem;

		&:not(:last-child) {
			padding-bottom: 1.5rem;
			border-bottom: 1px solid $color-gray-1;
		}
	}
}

.loading {
	height: 25px;
	background-color: #fff;

	&Sm {
		width: 110px;
	}

	&Md {
		width: 150px;
	}

	&Lg {
		width: 180px;
	}
}


.switcherBox {
	position: relative;
	z-index: 121;
}

.boxSection {
	background-color: #f4f4f4;
	padding: 32px;
	border-radius: 16px;
}