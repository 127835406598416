@import '../modules';

$checkbox_size: 18px;
$radio-checked-size: 8px;

%checkbox {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	position: relative;
	border: 2px solid $color-gray-3;
	cursor: pointer;
	flex-shrink: 0;
	top: 3px;
	color: #fff;
	transition: border-color ($transition-timing - 0.05);
}

%disabled {
	opacity: 0.6;
	background-color: $color-gray-2;
	box-shadow: none;
	border-color: transparent;
	color: $color-gray-3 !important;
}

.form-box {
	padding: 20px 15px;
	border-radius: $border-radius-15;
	background-color: #fff;
	box-shadow: 0 7px 21px $box-shadow-color;

	@include respond-above(xxs) {
		padding: 20px;
	}

	@include respond-above(sm) {
		padding: 30px;
	}
}

label.form-label {
	margin-bottom: 0.25rem;
	padding-left: 2px;
	padding-right: 3px;
}

.form-check {
	display: flex !important;
	padding-left: 0;
	margin-bottom: 10px;

	.form-label {
		cursor: pointer;
		margin-bottom: 0 !important;
		padding-left: 7px;
		padding-right: 0;
		line-height: 1.65em;
		flex-grow: 1;
	}

	&.disabled {
		.form-label {
			cursor: default;
		}
	}
}

.form-input {
	&-check {
		display: none;

		&:checked,
		&:focus {
			+span {
				color: $color-gray-4;
				background-color: $color-secondary;
				border-color: $color-secondary;
			}
		}

		&[disabled] {
			+span {
				cursor: default;
				color: $color-gray-3;
				background-color: $color-gray-2;
				border-color: $color-gray-2;
			}
		}
	}

	&-number {
		max-width: 65px;
	}

	&-color {
		width: 35px;
		height: 35px;
		border-radius: 5px;
		border: 1px solid $color-gray-1;
		flex-shrink: 0;
	}
}

.form-check-tick {
	@extend %checkbox;
	width: $checkbox-size;
	height: $checkbox-size;
	border-radius: 5px;

	.disabled & {
		background-color: rgba($color-gray-2, 0.6);
		pointer-events: none;
		cursor: pointer;
		border-color: #e6e6e9 !important;
		color: transparent;
	}

	&:hover {
		color: $color-gray-2;
	}
}

.form {
	&-radio {
		&-label {
			cursor: pointer;
			padding-left: 5px;
		}

		&-input {
			background-size: initial;
			width: 18px;
			height: 18px;
			cursor: pointer;
			padding-left: 0 !important;
			margin-right: 0 !important;
			margin-top: 2px !important;
			border: 2px solid $color-gray-2;
			border-radius: 50%;
			appearance: none;
			position: relative;
			flex-shrink: 0;

			&:checked {
				background-color: #fff;
				border-color: $color-secondary-light;

				&:after {
					content: '';
					position: absolute;
					top: 50%;
					left: 50%;
					width: 10px;
					height: 10px;
					margin-top: -5px;
					margin-left: -5px;
					border-radius: 50px;
					background-color: $color-secondary;
				}
			}

			&:focus {
				border-color: $color-secondary-light;
			}
		}
	}

	&-check-input {
		background-size: initial;
		width: 18px;
		height: 18px;
		cursor: pointer;
		padding-left: 0 !important;
		margin-left: 0 !important;
		margin-top: 3px !important;
		border: 1.5px solid $color-gray-2;

		&:checked {
			background-color: $color-secondary;
			box-shadow: initial !important;
			border-color: $color-secondary;
			background-size: 104%;
		}

		&:focus {
			border-color: $color-secondary;
		}
	}
}

.form-radio-tick {
	@extend %checkbox;
	width: $checkbox-size + 1;
	height: $checkbox-size + 1;
	border-radius: 50px;

	&.checked {
		border-color: $color-secondary-light;

		&:before {
			content: '';
			width: $radio-checked-size + 1;
			height: $radio-checked-size + 1;
			border-radius: 50%;
			background-color: $color-secondary;
		}
	}
}

.inputbox {
	$parent: &;
	color: $color-gray-5;
	background-color: rgba($color-gray-1, 0.3);
	box-shadow: 0 5px 10px rgba($color-gray-5, 0.05) inset;
	display: flex;
	align-items: center;
	height: 40px;
	padding: 10px 15px;
	flex-grow: 1;
	min-width: 1px;
	border: 2px solid rgba($color-gray-5, 0.07);
	border-radius: $input-border-radius;
	transition: all $transition-timing;

	&:focus,
	&:focus-within,
	&:active {
		border-color: $color-gray-3;
		box-shadow: none;
		background-color: #fff;
	}

	&[disabled] {
		@extend %disabled;
	}

	&[readonly] {
		background-color: $color-gray-1;
		border-color: transparent;
		cursor: default;
	}

	@at-root textarea#{$parent} {
		height: 150px;
		line-height: 1.5;
		resize: none;
	}

	@include placeholder {
		color: $color-gray-3;
	}
}

input[type='search'] {

	&::-webkit-search-decoration,
	&::-webkit-search-cancel-button,
	&::-webkit-search-results-button,
	&::-webkit-search-results-decoration {
		display: none;
	}
}

.otp {
	width: 40px !important;
	padding: 5px !important;
}

.react-select--is-disabled {
	@extend %disabled;
}