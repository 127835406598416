@import '../../../modules';

.toggle {
    position: relative;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-size: 13px;
    padding: 0.25rem 0.25rem 0.25rem 10px;
    border-radius: 5px;
    height: 35px;
    // box-shadow: 0 5px 10px $box-shadow-color;
}

.arrow {
    margin-left: 0.25rem;

    :global(.show) & {
        transform: rotate(-180deg);
    }
}

.menu {
    padding: 1rem;
    width: 22rem;
    border: none !important;
    margin-top: 0.5rem !important;
    border-radius: 10px !important;
    box-shadow: 0 5px 10px $box-shadow-color !important;
}

// .item {
//     height: 40px;
//     display: flex !important;
//     align-items: center !important;
//     font-size: 14px !important;
//     color: $color-gray-4 !important;
// }