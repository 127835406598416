@import '../../modules';

.card {
	padding: 1rem;
	border-radius: 15px;
	box-shadow: 0 5px 10px $box-shadow-color;
	background-color: #fff;
	position: relative;

	@include respond-above(xs) {
		padding: 1.25rem 1rem;
	}

	@include respond-above(lg) {
		padding: 2rem 1.5rem;
	}

	&:before {
		content: '';
		position: absolute;
		left: 0;
		right: 20%;
		top: 0;
		bottom: 0;
		opacity: 0.3;
		border-radius: 15px 0 0 15px;
	}

	&.paid {
		&:before {
			background: -moz-linear-gradient(
				left,
				rgba($color-green, 1) 0%,
				rgba(255, 255, 255, 1) 100%
			);
			background: -webkit-linear-gradient(
				left,
				rgba($color-green, 1) 0%,
				rgba(255, 255, 255, 1) 100%
			);
			background: linear-gradient(
				to right,
				rgba($color-green, 1) 0%,
				rgba(255, 255, 255, 1) 100%
			);
		}
	}

	&.pending {
		&:before {
			background: -moz-linear-gradient(
				left,
				rgba($color-yellow, 1) 0%,
				rgba(255, 255, 255, 1) 100%
			);
			background: -webkit-linear-gradient(
				left,
				rgba($color-yellow, 1) 0%,
				rgba(255, 255, 255, 1) 100%
			);
			background: linear-gradient(
				to right,
				rgba($color-yellow, 1) 0%,
				rgba(255, 255, 255, 1) 100%
			);
		}
	}

	&.expired {
		&:before {
			background: -moz-linear-gradient(
				left,
				rgba($color-gray-3, 1) 0%,
				rgba(255, 255, 255, 1) 100%
			);
			background: -webkit-linear-gradient(
				left,
				rgba($color-gray-3, 1) 0%,
				rgba(255, 255, 255, 1) 100%
			);
			background: linear-gradient(
				to right,
				rgba($color-gray-3, 1) 0%,
				rgba(255, 255, 255, 1) 100%
			);
		}
	}

	&.rejected {
		&:before {
			background: -moz-linear-gradient(
				left,
				#ff4a4a 0%,
				rgba(255, 255, 255, 1) 100%
			);
			background: -webkit-linear-gradient(
				left,
				#ff4a4a 0%,
				rgba(255, 255, 255, 1) 100%
			);
			background: linear-gradient(
				to right,
				#ff4a4a 0%,
				rgba(255, 255, 255, 1) 100%
			);
		}
	}

	&:not(:last-child):not(.switchedParent):not(.switched) {
		margin-bottom: 1.25rem;
	}
}

.switched {
	$parent: &;
	background-image: repeating-linear-gradient(
		to right,
		transparent,
		transparent 7.5px,
		$color-gray-3 7.5px,
		$color-gray-3 15px
	);
	background-position: top;
	background-repeat: repeat-x;
	background-size: auto 1px;
	border-radius: 0;

	&::before {
		border-radius: 0;
	}

	&Parent {
		border-radius: 15px 15px 0 0;

		&::before {
			border-radius: 15px 0 0 0;
		}
	}

	&.last {
		border-radius: 0 0 15px 15px;

		&::before {
			border-radius: 0 0 0 15px;
		}
	}
}

.row {
	display: flex;
	align-items: center;
	min-height: 2rem;
	margin-bottom: 0.5rem;
	flex-wrap: wrap;

	&Label {
		padding-right: 0.5rem;
		min-width: 170px;
	}

	@include respond-below(xs) {
		justify-content: space-between;
	}

	@include respond-below(sm) {
		padding-bottom: 0.5rem;
		border-bottom: 1px solid $color-gray-1;

		&:last-child {
			border-bottom: 0;
			margin-bottom: 1.25rem;
		}
	}
}

.col {
	display: flex;
	flex-direction: column;
	line-height: 27px;
	flex-basis: calc(33.3% - 1rem);
	flex-grow: 1;
	gap: 0.5rem;
}

.logo {
	width: 2rem;
	height: 2rem;
	flex-shrink: 0;
	object-fit: contain;
	padding: 3px;
	margin-left: 0;
	margin-right: 0;
	border-radius: 50px;
	background-color: #fff;
	box-shadow: 0 5px 10px $box-shadow-color;

	&Md {
		width: 3rem;
		height: 3rem;
	}
}

.text {
	max-width: 650px;
}

.link {
	margin-top: 1rem;
	composes: button from '../common.scss';
	composes: button_md from '../common.scss';
	color: $color-primary !important;
	box-shadow: 0 5px 10px rgba($color-primary, 0.2);
	background-color: #fff;

	&:hover {
		background-color: $color-primary !important;
		color: #fff !important;
	}
}

@keyframes skeleton-loading {
	to {
		background-position: left;
	}
}

.skeletonLabel {
	background: linear-gradient(
			90deg,
			rgba(238, 236, 236, 0.667) 40%,
			#ffffffaa,
			rgba(238, 236, 236, 0.667) 60%
		)
		right / 300% 100%;
	animation: skeleton-loading 2.5s linear infinite;

	width: 100px;
	height: 20px;
	border-radius: 4px;
}
