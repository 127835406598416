@import '../../modules';

.card {
	padding: 16px 16px 24px 16px;
	position: relative;
}

.selected {
	padding: 16px 16px 24px 16px;
	border-radius: 16px;
	background-color: rgba($color-primary-light, 0.2) !important;
	outline: 1px solid $color-primary;
	box-shadow: 0 5px 10px rgba($color-primary, 0.1) !important;
}

.cardPlan {
	p {
		margin: 0 !important;
		padding: 0 !important;
		line-height: normal !important;
	}
	ul,
	ol {
		list-style: revert !important;
		list-style-image: inherit !important;
		padding: revert !important;
		margin: auto !important;
		li {
			list-style: unset !important;
			list-style-image: 0 !important;
		}
	}
}
