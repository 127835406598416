@import '../../modules';

$image-width: 110px;
$image-height: 110px;

.card {
    min-height: 280px;
    padding: 15px 15px 20px !important;
    height: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;

    @include respond-below(sm) {
        max-width: 340px;
        margin-right: auto;
        margin-left: auto;
    }
}


.avatar {
    width: $image-width;
    height: $image-height;
    box-shadow: 0 5px 10px $box-shadow-color;
    border-radius: 50%;
    margin: 20px auto;
}

.shimmer {
    object-fit: cover;
    border-radius: 50%;
    width: 100%;
    height: 100%;

    &Box {
        border-radius: 50%;
        overflow: hidden;
        display: block;
        width: 100%;
        height: 100%;
    }
}

.body {
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
}

.badge {
    position: absolute;
    right: 15px;
    top: 15px;
}

.loading {
    height: 20px;

    &Sm {
        width: 110px;
    }

    &Md {
        width: 180px;
    }

    &Lg {
        width: 140px;
        height: 30px;
    }
}