@import '../../../modules';

.logo {
	width: 100%;
	height: 100%;
	object-fit: contain;
	padding: 0.25rem;

	&Wrapper {
		border-radius: 10px;
		width: 4.5rem;
		height: 4.5rem;
		background-color: white;
		overflow: hidden;
		flex-shrink: 0;

	}
}

.description,
.description>p {
	-webkit-line-clamp: 4;
	overflow: hidden;
	display: -webkit-box;
	-webkit-box-orient: vertical;
}

.loading {
	height: 24px;
}