@import '../../modules';

.filter_section {
	margin-bottom: 2rem;
}

.image {
	width: 100%;
	height: 100%;
	object-fit: cover;
	background-color: $color-gray-1;

	&Grid {
		display: grid;
		gap: 10px;
		grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
	}

	&Wrapper {
		cursor: pointer;
		height: 80px;
		border: 3px solid transparent;
		border-radius: 10px;
		overflow: hidden;
		box-shadow: 0 5px 15px $box-shadow-color;
	}
}

.selectedImage {
	border-color: rgba($color-primary, 0.9);
}