@import '../../modules';

.badge {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
	padding-right: 0.5rem;
	padding-left: 0.5rem;
	border-radius: 5px;
	font-size: 12px;
	font-weight: 400;
	min-height: 25px;
}

$badge-colors: (
	'success': $color-green,
	'green': $color-green,
	'warning': $color-yellow,
	'yellow': $color-yellow,
	'error': $color-red,
	'red': $color-red,
	'muted': $color-gray-3,
	'gray-3': $color-gray-3,
	'gray-4': $color-gray-4,
	'gray-5': $color-gray-5,
	'primary': $color-primary,
	'tertiary': $color-tertiary,
	'tertiary-dark': $color-tertiary-dark,
	'premium': 'premium',
	'association': $color-blue,
	'job': $color-yellow-job
);

@each $color_key, $color_value in $badge-colors {
	.#{$color_key} {
		@if ($color_key == 'premium') {
			background: linear-gradient(
				93deg,
				#fddd4e 8.04%,
				#fbf1ab 51.59%,
				#fbd94d 98.93%
			);
			text-align: center;
			color: #c37d16;
		} @else {
			background-color: rgba($color_value, 0.2);
			color: $color_value;
		}
	}
}
