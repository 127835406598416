@import '../../modules';
@import '../../core/animation';

$image-height: 13rem;

.control {
	position: relative;
	display: flex;
	align-items: center;
	flex-wrap: wrap;

	&:hover .edit {
		color: white;
		animation-name: fadeIn;
	}
}

.cover {
	width: 100%;
	aspect-ratio: 16/9;
	// height: 100%;
	margin: 0;
	object-fit: cover;
	border-radius: 15px;
	overflow: hidden;
	background-color: $color-gray-1;

	&Box {
		width: 100%;
		min-height: $image-height;
		aspect-ratio: 16/9;
		border-radius: 15px;
		cursor: pointer;
		flex-shrink: 0;
	}

	&Default {
		background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23D5D5DBFF' stroke-width='2' stroke-dasharray='5' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
		background-color: rgba($color-gray-1, 0.5);
		width: 100%;
		min-height: $image-height;
		aspect-ratio: 16/9;
		border-radius: 10px;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		color: $color-primary;
	}
}

.body {
	flex-grow: 1;
}

.edit {
	position: absolute;
	right: 1rem;
	top: 1rem;
	background-color: #fff;
	padding: 0.25rem;
	width: 2.5rem;
	height: 2.5rem;
	min-height: 2.5rem;
	color: $color-primary;
	border-radius: 10px;
	animation-name: fadeOut;
	animation-fill-mode: forwards;
	animation-duration: $transition-timing + 0.05;
}
