@import '../../modules';
// @import '../../../core/animation';

.box {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='15' ry='15' stroke='%2396BD68FF' stroke-width='3' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
    border-radius: 15px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    overflow: hidden;
    text-align: center;
    color: $color-gray-3;
    transition: background-color $transition-timing;

    &:hover {
        background-color: rgba(#fff, 0.8);
    }
}

.progress {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    transition: width $transition-timing;
    background-color: rgba($color-primary-light, 0.5);
    border-radius: 15px 0 0 15px;
    mix-blend-mode: multiply;

    &Value {
        left: 8px;
        top: 8px;
        position: absolute;
    }

    // &Complete {
    //     animation-fill-mode: forwards;
    //     animation-duration: 300ms;
    //     animation-name: fadeOut;
    // }
}