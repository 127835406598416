@import '../../modules';

.box {
	padding: 1rem;

	@include respond-above(xs) {
		padding: 1.5rem;
	}

	@include respond-above(xl) {
		padding: 2rem;
	}
}

.border_box {
	position: relative;

	&:before {
		content: "";
		position: absolute;
		height: 1px;
		right: 0;
		left: 0;
		bottom: 0;
		background-image: linear-gradient(90deg, $color-gray-2, $color-gray-2 75%, transparent 75%, transparent 100%);
		background-size: 20px 1px;

		@include respond-above(sm) {
			height: 100%;
			right: 0;
			left: auto;
			top: 0;
			width: 1px;
			background-image: repeating-linear-gradient(-22deg,
					$color-gray-2,
					$color-gray-2 3.04px,
					transparent 8px,
					transparent 10px,
					$color-gray-2 16px),
				repeating-linear-gradient(68deg,
					$color-gray-2,
					$color-gray-2 3.04px,
					transparent 8px,
					transparent 10px,
					$color-gray-2 16px),
				repeating-linear-gradient(158deg,
					$color-gray-2,
					$color-gray-2 3.04px,
					transparent 8px,
					transparent 10px,
					$color-gray-2 16px),
				repeating-linear-gradient(248deg,
					$color-gray-2,
					$color-gray-2 3.04px,
					transparent 8px,
					transparent 10px,
					$color-gray-2 16px);
			background-size: 0px 100%, 0 0px, 1px 100%, 100% 0px;
			background-position: 0 0, 0 0, 100% 0, 0 0;
			background-repeat: no-repeat;
		}

	}
}

.logo {
	width: 100%;
	height: 100%;
	margin-left: 0;
	margin-right: 0;
	object-fit: contain;
	border-radius: 5px;

	&Wrapper {
		overflow: hidden;
		flex-shrink: 0;
		width: 45px;
		height: 45px;
		border-radius: 5px;
	}
}

.row {
	display: flex;
	align-items: center;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;

	&:not(:last-of-type) {
		border-bottom: 1px solid $color-gray-1;
	}
}

.label {
	min-width: 180px;
	font-size: 16px;
	margin-right: 0.5rem;
	display: flex;
	align-items: center;
	gap: 0.25rem;
}

.value {
	margin-left: auto;
	font-weight: 500;
	font-size: 16px;
	color: $color-gray-5;
	text-align: right;
}

.message {
	padding: 15px;
	border-radius: 10px;
	border: 1px solid $color-red;
	background-color: rgba($color-red, 0.1);

	&_title {
		color: $color-red;
		font-size: 16px;
		font-weight: 700;
	}
}

.icon {
	flex-shrink: 0;
	margin-right: 15px;
	color: $color-red;
}

.submit {
	min-width: 50%;
}

.back {
	margin-right: 10px;
}

.boxCompanyApprovalStatus {
	background-color: rgba($color-secondary, 0.1);
	border-bottom: 4px solid $color-secondary;
	padding: 20px;
	border-radius: 15px;
	margin-top: 3rem;
	margin-bottom: 3rem;
}