@import '../../modules';

$header_height_mobile: 3.75rem;
$header_height_desktop: 5.5rem;
$header_height_scroll: 4.5rem;

.title {
	position: relative;

	&:before {
		content: "";
		height: 1.25rem;
		width: 1.25rem;
		border-radius: 5px;
	}
}

.decoration {
	height: clamp(19rem, 50vw, 22rem);
	overflow: hidden;

	@include respond-below(md) {
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		z-index: -1;
	}

	@include respond-above(lg) {
		height: clamp(19rem, 50vw, 24rem);
	}

	&:before {
		content: '';
		position: absolute;
		right: 0;
		left: 0;
		top: 0;
		z-index: 0;
		height: clamp(19rem, 50vw, 22rem);
		background: -moz-linear-gradient(
			top,
			rgba(#fff, 0.8) 0%,
			rgba(0, 0, 0, 0) 100%
		);
		background: -webkit-linear-gradient(
			top,
			rgba(#fff, 0.8) 0%,
			rgba(0, 0, 0, 0) 100%
		);
		background: linear-gradient(
			to bottom,
			rgba(#fff, 0.8) 0%,
			rgba(0, 0, 0, 0) 100%
		);

		@include respond-above(lg) {
			height: clamp(19rem, 50vw, 24rem);
		}
	}

	&_content {
		padding-top: $header_height_desktop;

		@include respond-above(md) {
			padding-top: $header_height_desktop + 2.5rem;
		}

		@include respond-above(lg) {
			padding-top: $header_height_desktop + 4.5rem;
		}
	}
}

.left_content {
	padding-top: $header_height_mobile + 3rem;

	@include respond-above(md) {
		margin-top: -5.5rem !important;
		padding-top: 0;
	}
}
