@import '../../../modules';

.title {
	position: relative;

	&:before {
		content: "";
		height: 1.25rem;
		width: 1.25rem;
		border-radius: 5px;
	}
}

.content {
	font-size: 16px !important;
	line-height: 1.8em !important;
	font-weight: 300;

	@include respond-below(md) {
		font-size: 14px !important;
	}

	@include respond-above(xs) {
		padding-left: 1.75rem;
	}

	p:last-of-type {
		margin-bottom: 0;
	}

	img {
		border-radius: 15px;
		box-shadow: 0 5px 15px $box-shadow-color;
	}

	ul>li {
		line-height: 1.65em;
		padding-left: 1rem;
		position: relative;
		list-style: none;

		&:not(:last-child) {
			margin-bottom: 0.25rem;
		}

		&:before {
			content: "•";
			position: absolute;
			left: 0;
			font-size: 20px;
		}
	}
}