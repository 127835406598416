@import '../modules';

@each $available-color_key, $available-color_value in $text-colors {
	.text-#{$available-color_key} {
		color: $available-color_value !important;
	}
}

.text {
	font-size: 16px !important;
	line-height: 1.8em !important;
	font-weight: 300;

	@include respond-below(md) {
		font-size: 14px !important;
	}

	&-list {
		counter-reset: list;

		> li {
			position: relative;
			padding-left: 2rem;
			margin-bottom: 1rem;
			line-height: 1.6rem;

			&:before {
				counter-increment: list;
				content: counter(list);
				position: absolute;
				left: 0;
				top: 4px;
				border-radius: 50%;
				background-color: $color-primary-light;
				color: #fff;
				display: flex;
				align-items: center;
				justify-content: center;
				text-align: center;
				width: 1.25rem;
				height: 1.25rem;
				font-size: 12px;
			}

			> p {
				line-height: 1.8em;
			}
		}
	}

	&-justify {
		text-align: justify;
	}
}

.lh {
	&-sm {
		line-height: 1.5em !important;
	}

	&-base {
		line-height: 1.65em !important;
	}

	&-md {
		line-height: 1.8em !important;
	}
}

.fs {
	&-verySmall {
		font-size: 10px;
	}
	&-small {
		font-size: 12px;
	}
	&-sm {
		font-size: 14px;
	}

	&-normal,
	&-base {
		font-size: 16px;
	}
}

.fw-300,
.fw-light,
.font-weight-light {
	font-weight: 300 !important;
}

.fw-400,
.fw-regular,
.font-weight-regular {
	font-weight: 400 !important;
}

.fw-500,
.fw-semibold,
.font-weight-semibold {
	font-weight: 500 !important;
}

.fw-700,
.fw-bold,
.font-weight-bold {
	font-weight: 700 !important;
}

.heading-1,
.h1,
.fs-1 {
	font-size: 46px !important;

	@include respond-above(md) {
		font-size: 48px !important;
	}
}

.heading-2,
.h2,
.fs-2 {
	font-size: 36px !important;

	@include respond-above(md) {
		font-size: 38px !important;
	}
}

.heading-3,
.h3,
.fs-3 {
	font-size: 28px !important;

	@include respond-above(md) {
		font-size: 30px !important;
	}
}

.heading-4,
.h4,
.fs-4 {
	font-size: 22px !important;

	@include respond-above(md) {
		font-size: 24px !important;
	}
}

.heading-5,
.h5,
.fs-5 {
	font-size: 18px !important;

	@include respond-above(md) {
		font-size: 20px !important;
	}
}

.heading-6,
.h6,
.fs-6 {
	font-size: 16px !important;

	@include respond-above(md) {
		font-size: 18px !important;
	}
}

.heading-2,
.h2,
.heading-3,
.h3,
.heading-4,
.h4,
.heading-5,
.h5,
.heading-6,
.h6 {
	font-weight: 700;
}

.invalid-feedback {
	margin-top: 0.25rem;
	font-size: 12px;
	width: 100%;
	color: $color-red;
	padding-left: 2px;
}
