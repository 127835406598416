@import '../../modules';

.qrbox {
    min-height: 345px;

    :global(#qr-shaded-region>div) {
        background-color: $color-primary !important;
    }

    :global(#html5-qrcode-anchor-scan-type-change) {
        display: none;
    }

    :global(#html5-qrcode-button-camera-permission) {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-weight: 400;
        border: 1px solid transparent;
        border-radius: $border-radius-10;
        text-align: center;
        transition: all $transition-timing;
        line-height: 1.35;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        padding: 0.25rem 0.75rem;
        font-size: 13px;
        min-height: 2.25rem;
        background-color: $color-primary;
        color: #fff;
    }
}