@import '../../../modules';

.dropdown {
    @include respond-below(sm) {
        position: absolute;
        right: 0;
        top: 0;
    }
}

.menu {
    width: 230px;
    border: none !important;
    margin-top: 0.5rem !important;
    border-radius: 10px !important;
    box-shadow: 0 5px 10px $box-shadow-color !important;
}