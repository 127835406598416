@import '../../modules';

.body {
    display: flex;
    position: relative;
    margin-bottom: 1rem;
}

.tab {
    display: flex;
    align-items: center;
    overflow-x: auto;
    white-space: nowrap;
}

.dropdown {
    @include respond-below(sm) {
        position: absolute;
        right: 0;
        top: 0;
    }
}

.meta {
    &Item {
        display: flex;
        align-items: center;
        margin-top: 1rem;
        gap: 0.5rem;

        &:not(:last-of-type) {
            margin-right: 1rem;
        }
    }
}