@import '../../modules';

$image-width: 100%;
$image-height: 280px;

.card {
    min-height: 280px;
    padding: 15px !important;
    height: 100%;
    display: flex;
    flex-direction: column;

    @include respond-below(sm) {
        max-width: 340px;
        margin-right: auto;
        margin-left: auto;
    }
}

.avatar {
    width: $image-width;
    height: $image-height;
    border-radius: 10px;
    margin-bottom: 20px;
    box-shadow: 0 5px 10px $box-shadow-color;
}

.shimmer {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;

    &Box {
        border-radius: 10px;
        overflow: hidden;
        display: block;
        width: 100%;
        height: 100%;
    }
}

.body {
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
}

.loading {
    height: 20px;

    &Sm {
        width: 110px;
    }

    &Md {
        width: 180px;
    }

    &Lg {
        width: 140px;
        height: 30px;
    }
}