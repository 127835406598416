@import '../../modules';

$page-num-size: 35px;
.row {
	display: flex;
	align-items: center;
	justify-content: center;
	padding-top: 10px;
	padding-bottom: 10px;
}

.pagination {
	display: flex;
	justify-content: center;
	align-items: center;
}

.num {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 14px;
	font-weight: 500;
	padding-right: 10px;
	padding-left: 10px;
	height: $page-num-size;
	width: $page-num-size;
	margin-left: 3px;
	margin-right: 3px;
	color: $color-gray-4;
	border-radius: 50%;
	line-height: 1;
	flex-shrink: 0;
	&:hover {
		background-color: $color-gray-1;
	}
}

.current {
	color: $color-secondary-dark !important;
	background-color: #fff;
	box-shadow: 0 5px 10px $box-shadow-color;
	font-weight: 700;
	cursor: default;
	pointer-events: none;
}

.arrow {
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	&:not(.disabled):hover {
		.num {
			background-color: $color-gray-1 !important;
		}
	}
}

.disabled {
	.num,
	&.arrow {
		background-color: transparent !important;
		color: $color-gray-2;
		cursor: default;
		// opacity: 0.8;
	}
}
