@import 'variables';

.#{$rt-namespace}__close-button {
	position: absolute;
	right: 5px;
	top: 5px;
	left: auto;
	color: #fff;
	width: 20px;
	height: 20px;
	opacity: 0.7;
	transition: 0.3s ease;
	display: none;
	// align-items: center;
	// justify-content: center;
	&--default {
		color: #000;
		opacity: 0.3;
	}

	& > svg {
		fill: currentColor;
		height: 16px;
		width: 14px;
	}

	&:hover,
	&:focus {
		opacity: 1;
	}
}
