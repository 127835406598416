@import '../../modules';

.action {
    width: 2.5rem;
    padding: 0;
    flex-shrink: 0;
}

.downloadPdf {
    composes: button from '../common.scss';
    color: $color-primary !important;
    box-shadow: 0 5px 10px rgba($color-primary, 0.2);
    background-color: #fff;
    cursor: pointer;

    &:hover {
        background-color: $color-primary !important;
        color: #fff !important;
    }
}

.submit {
    min-height: 45px;
}

.logo {
    max-width: 2.5rem;
    height: 2.5rem;
    margin: 0 0.25rem 0.25rem 0px;

    img {
        width: 100%;
        height: 100%;
        background: #fff;
        object-fit: contain;
        border: 1px solid $color-gray-1;
        border-radius: 50px;
    }
}