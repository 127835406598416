@import '../../../modules';

.switch {
    margin-bottom: 1.5rem;
    padding-top: 1.5rem;

    &.active {
        position: relative;
        z-index: $overlay-z-index + 1;
    }
}

.carousel {
    padding: 0.5rem 1.75rem 2rem;
}

.card {
    height: auto;
}

.bullets {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 1.5rem;
    gap: 0.5rem;
}

.bullet {
    display: flex;
    align-items: center;
    width: 10px;
    height: 10px;
    cursor: pointer;
    border-radius: 10px;
    transition: width $transition-timing;
    opacity: 0.2;

    &.active {
        opacity: 1;
        width: 25px;
    }
}

.boxSection {
	background-color: #f4f4f4;
	padding: 32px;
	border-radius: 16px;
}

.switcherBox {
	position: relative;
	z-index: 121;
}