@import '../../modules';

.slider {
	border-radius: 20px;
	background-color: $color-primary-light;
	box-shadow: none;
	border: 0;
	height: 5px;
	margin-top: 2.5rem;

	:global(.noUi-pips-horizontal) {
		padding: 0.5rem 0;
	}
	:global(.noUi-value) {
		font-size: 12px;
		margin-top: 0;
	}
	:global(.noUi-marker-large) {
		background-color: $color-gray-2;
		width: 1px;
		height: 0.5rem;
	}
	:global(.noUi-tooltip) {
		background-color: $color-gray-1;
		// border-color: $color-gray-2;
		border: 0;
		padding: 0.25rem 0.5rem;
		font-size: 12px;
		border-radius: 4px;
		color: $body-color;
	}
	:global(.noUi-handle) {
		width: 18px;
		height: 18px;
		border-radius: 50%;
		background-color: $color-primary;
		border: 0;
		box-shadow: none;
		top: -7px;
		right: -9px;
		cursor: pointer;

		&:before,
		&:after {
			display: none;
		}
	}
}

.level {
	flex: 1 0 0;
	min-height: 4.25rem;
	@include respond-above(xxl) {
		max-width: 9rem;
		width: 100%;
	}
}

.loading {
	height: 20px;
}
