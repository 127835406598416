@import '../../modules';

.image {
    width: 250px;
    height: 250px;
    margin-right: auto;
    margin-left: auto;
}

.link {
    
}