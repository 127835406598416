@import '../core/animation';

.z-index-1 {
	z-index: 1;
}

.flex-1 {
	flex: 1 1 0;
}

.pre-line {
	white-space: pre-line;
}

.c-pointer,
.cursor-pointer {
	cursor: pointer;
}

.object {
	&-cover {
		object-fit: cover !important;
	}

	&-contain {
		object-fit: contain !important;
	}
}

.border {
	&-solid {
		border-style: solid;
	}

	&-dashed {
		border-style: dashed;
	}
}

.round {
	&-5 {
		border-radius: 5px;
	}

	&-10 {
		border-radius: 10px;
	}

	&-15 {
		border-radius: 15px;
	}

	&-20 {
		border-radius: 20px;
	}
}

.overlay {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 2;
	background-color: rgba($color-gray-5, 0.75);
	animation-fill-mode: forwards;
	animation-duration: $transition-timing + 0.05;

	&_white {
		background-color: rgba(#fff, 0.75) !important;
	}

	&.show {
		animation-name: fadeIn;
	}

	&.hiding {
		animation-name: fadeOut;
	}
}

.overlay-content {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: $overlay-content-z-index;
	border-radius: $input-border-radius;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba(#000, 0.1);
}

.rotate-180 {
	transform: rotate(180deg);
}

.opacityPulse {
	animation-name: opacityPulse;
	animation-iteration-count: 5;
	animation-duration: 2.5s;
	animation-timing-function: ease-in-out;

	&.infinite {
		animation-iteration-count: infinite;
	}
}

.edit-overlay {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	position: absolute;
	right: 0;
	left: 0;
	bottom: 0;
	top: 0;
	// z-index: 1;
	border-radius: 15px;
	transition: background-color $transition-timing + 0.05;

	&:hover {
		background-color: rgba($color-primary, 0.8);
	}

	&-icon {
		position: absolute;
		right: 1rem;
		top: 1rem;
		background-color: #fff !important;
		padding: 0.5rem !important;
		width: 45px !important;
		height: 45px !important;
		border-radius: 10px !important;
	}

	// animation-name: fadeOut;
	// animation-fill-mode: forwards;
	// animation-duration: $transition-timing + 0.05;
}

.back {
	&-icon {
		transition: transform $transition-timing;
	}

	&-button:hover {
		.back-icon {
			transform: translate3d(-3px, 0, 0);
		}
	}
}
