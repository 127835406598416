@import '../../../modules';

.toggle {
    box-shadow: 0 5px 10px $box-shadow-color;
    position: relative;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-size: 13px;
    padding: 0.25rem 0.25rem 0.25rem 10px;
    border-radius: 5px;
    height: 35px;
}

.arrow {
    margin-left: 0.25rem;

    :global(.show) & {
        transform: rotate(-180deg);
    }
}

.menu {
    width: 9.5rem;
    border: none !important;
    margin-top: 0.5rem !important;
    border-radius: 10px !important;
    box-shadow: 0 5px 10px $box-shadow-color !important;
}

.item {
    height: 40px;
    display: flex !important;
    align-items: center !important;
    font-size: 14px !important;
    color: $color-gray-4 !important;

    &:hover {
        background-color: $color-gray-1 !important;
        color: $color-primary;
    }
}

.logo {
    width: 100%;
    height: 100%;
    margin-left: 0;
    border-radius: 5px;
    object-fit: contain;

    &Wrapper {
        width: 25px;
        height: 25px;
        flex-shrink: 0;
        border-radius: 5px;
        overflow: hidden;
    }
}