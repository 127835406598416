@import '../modules';

$spacer: 1rem !default;
$spacers: (
    6: $spacer * 6,
    7: $spacer * 12,
);

@each $key, $value in $spacers {
    .mt-#{$key} {
        margin-top: $value !important;
    }

    .mb-#{$key} {
        margin-bottom: $value !important;
    }

    .ms-#{$key} {
        margin-left: $value !important;
    }

    .me-#{$key} {
        margin-right: $value !important;
    }

    .mx-#{$key} {
        margin-right: $value !important;
        margin-left: $value !important;
    }

    .my-#{$key} {
        margin-top: $value !important;
        margin-bottom: $value !important;
    }

    @each $grid_key, $grid_value in $breakpoints {
        @include respond-above($grid_key) {
            .mt-#{$grid_key}-#{$key} {
                margin-top: $value !important;
            }

            .mb-#{$grid_key}-#{$key} {
                margin-bottom: $value !important;
            }

            .ms-#{$grid_key}-#{$key} {
                margin-left: $value !important;
            }

            .me-#{$grid_key}-#{$key} {
                margin-right: $value !important;
            }

            .mx-#{$grid_key}-#{$key} {
                margin-right: $value !important;
                margin-left: $value !important;
            }

            .my-#{$grid_key}-#{$key} {
                margin-top: $value !important;
                margin-bottom: $value !important;
            }
        }
    }
}

@each $key, $value in $spacers {
    .pt-#{$key} {
        padding-top: $value !important;
    }

    .pb-#{$key} {
        padding-bottom: $value !important;
    }

    .ps-#{$key} {
        padding-left: $value !important;
    }

    .pe-#{$key} {
        padding-right: $value !important;
    }

    .px-#{$key} {
        padding-right: $value !important;
        padding-left: $value !important;
    }

    .py-#{$key} {
        padding-top: $value !important;
        padding-bottom: $value !important;
    }

    @each $grid_key, $grid_value in $breakpoints {
        @include respond-above($grid_key) {
            .pt-#{$grid_key}-#{$key} {
                padding-top: $value !important;
            }

            .pb-#{$grid_key}-#{$key} {
                padding-bottom: $value !important;
            }

            .ps-#{$grid_key}-#{$key} {
                padding-left: $value !important;
            }

            .pe-#{$grid_key}-#{$key} {
                padding-right: $value !important;
            }

            .px-#{$grid_key}-#{$key} {
                padding-right: $value !important;
                padding-left: $value !important;
            }

            .py-#{$grid_key}-#{$key} {
                padding-top: $value !important;
                padding-bottom: $value !important;
            }
        }
    }
}