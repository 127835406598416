@import '../../modules';

$arrow-size: 70px;

.navigation {
	position: absolute;
	top: 50%;
	left: 0;
	right: 0;
	z-index: 5;
	transform: translateY(-50%);
	display: flex;
	align-items: center;
}

.arrow {
	width: $arrow-size;
	height: $arrow-size;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: color $transition-timing;
	z-index: 1;

	> svg {
		transition: transform $transition-timing;
	}

	.light & {
		color: $color-gray-4;
	}

	.dark & {
		color: $color-gray-1;
	}
}

.next {
	margin-left: auto;
	position: absolute;
	right: -1.5rem;

	&:not(.disable):hover {
		svg {
			transform: translateX(3px) scale(0.93);
		}
	}
}

.prev {
	position: absolute;
	left: -1.5rem;

	&:not(.disable):hover {
		svg {
			transform: translateX(-3px) scale(0.93);
		}
	}
}

.disable {
	opacity: 0.6;

	.light &,
	.dark & {
		color: $color-gray-2;
	}
}
