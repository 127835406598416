@import '../modules';

.modal {
	&-backdrop {
		background: $color-gray-5 !important;
		opacity: 0.9 !important;
	}

	&-content {
		border-radius: 15px !important;
		border: none !important;
		box-shadow: 0 10px 20px $box-shadow-color;
		padding: calc($modal-padding / 2) $modal-padding $modal-padding;
	}
}