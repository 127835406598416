@import '../../modules';
@import '../../core/animation';

.control {
	position: relative;
	display: flex;
	align-items: center;
	flex-wrap: wrap;

	&:hover .edit {
		color: white;
		animation-name: fadeIn;
	}
}

.avatar {
	width: 100%;
	height: 100%;
	margin: 0;
	object-fit: cover;
	border-radius: 15px;
	background-color: $color-gray-1;

	&Box {
		width: 100%;
		height: 100%;
		border-radius: 15px;
		cursor: pointer;
	}
}

.box {
	border-radius: 15px;
	border: 3px dashed rgba($color-gray-2, 0.4);
	width: 100%;
	height: 320px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	cursor: pointer;

	@include respond-above(xl) {
		height: 450px;
	}
}

.add {
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	background-color: $color-primary;
	color: #fff;
	width: 45px;
	height: 45px;
}

.edit {
	position: absolute;
	right: 1rem;
	top: 1rem;
	background-color: #fff;
	padding: 0.25rem;
	width: 2.5rem;
	height: 2.5rem;
	min-height: 2.5rem;
	color: $color-primary;
	border-radius: 10px;
	animation-name: fadeOut;
	animation-fill-mode: forwards;
	animation-duration: $transition-timing + 0.05;
}
