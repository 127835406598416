$start: 0;
$end: 9;

@each $available-color_key,
$available-color_value in $bg-colors {
	.bg-#{$available-color_key} {
		background-color: $available-color_value !important;
	}

	@for $i from $start through $end {
		.bg-#{$available-color_key}_o-#{$i} {
			background-color: rgba($available-color_value, calc(($i*10)/100)) !important;
		}
	}
}