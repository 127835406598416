@import '../../modules';

.config {
	display: flex;
	flex-direction: column;
	height: 100%;

	&Box {
		margin-bottom: 15px;
	}

	:global(.advanced-cropper-preview__content) {
		border-radius: 10px;
	}
}

.cropper {
	background: $color-gray-5 !important;
	border-radius: 10px;
	height: 100%;

	&Back {
		background-color: transparent;
		color: rgba($color-gray-5, 0.85);
	}

	&Line {
		border-color: $color-secondary !important;
	}

	&LineHover {
		border-color: $color-secondary-dark !important;
	}

}

.preview {
	width: 16rem;
	height: 9rem;
	overflow: hidden;
}

.setting {
	&Item {
		padding: 0 !important;
		border-radius: 10px !important;
	}

	&Grid {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr;
		gap: 0.5rem;
	}
}