@import '../../../modules';
@import '../../../core/animation';

.headerImage {
	width: 100%;
	position: relative;
	overflow: hidden;
	border-radius: 30px;
	background: #fff;
}

.image {
	background-color: $color-gray-1;
	object-fit: cover;
	width: 100%;
	height: 100%;
	border-radius: 20px;
	min-height: 28rem;
}

.editable {
	width: 100%;
	height: 100%;
	position: relative;
	min-height: 450px;

	:global(.edit-overlay) {
		border-radius: 20px !important;
	}

	&:hover {
		:global(.edit-overlay) {
			animation-name: fadeIn;
		}
	}
}
