@import '../../../modules';
@import '../../../core/animation';

.mask {
    animation-name: pulse;
    animation-duration: 0.8s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    background-color: $color-gray-1;
    border-radius: 4px;
}

@-webkit-keyframes pulse {
	0% {
		opacity: 0.5;
	}

	100% {
		opacity: 1;
	}
}

@-moz-keyframes pulse {
	0% {
		opacity: 0.5;
	}

	100% {
		opacity: 1;
	}
}

@keyframes pulse {
	0% {
		opacity: 0.5;
	}

	100% {
		opacity: 1;
	}
}