@import '../../../modules';

.decoration {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: -1;
    height: clamp(18.75rem, 50vw, 25rem);
    overflow: hidden;

    &:before {
        content: '';
        position: absolute;
        right: 0;
        left: 0;
        top: 0;
        z-index: 1;
        height: 100%;
        background: -moz-linear-gradient(top, rgba(#fff, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
        background: -webkit-linear-gradient(top, rgba(#fff, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
        background: linear-gradient(to bottom, rgba(#fff, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
    }
}

.content {
    padding-top: $header_height_desktop;

    @include respond-above(sm) {
        padding-top: $header_height_desktop + 30;
    }
}