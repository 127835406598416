@import '../../modules';

.wrapper {
	composes: sectionWrapper from '../common.scss';
	background-color: $color-gray-1;
}

.check {
	color: $color-primary;
}

.container {
	min-height: 390px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.box {
	border-radius: 50%;
	padding: 20px;
	margin-top: 30px;
	position: relative;
}

.filter {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background-color: #fff;
	filter: blur(50px);
	border-radius: 50%;
}

.body {
	position: relative;
	z-index: 1;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.error {
	margin-bottom: 30px;
	border-radius: 50%;
	width: 89px;
	height: 89px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 5px;
	color: $color-red;
	border: 4px solid $color-red;
}