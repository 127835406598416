@import '../../modules';

.card {
	display: flex;
	flex-direction: column;
	cursor: pointer;
	align-items: center;
	text-align: center;
	flex-shrink: 0;

	@include respond-below(sm) {
		width: 170px;
	}
}

.content {
	border-radius: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 1.25rem;
	width: 100%;
	background-color: $color-gray-1;
	margin-bottom: 0.5rem;
	border: 3px solid transparent;
}

.active {
	border-color: $color-primary-light;
}

.title {
	white-space: normal;
	text-align: center;
}

.image {
	width: 100%;
	height: 100%;
	height: 115px;
	object-fit: contain;
	flex-grow: 1;
}