@import '../../../modules';
@import '../../../core/animation';

$player_icon-width: 5rem;
$player_icon-height: 5rem;

$player_icon_responsive-width: 3rem;
$player_icon_responsive-height: 3rem;

.card {
	height: 100%;
	display: flex;
	flex-direction: column;
	border-radius: 30px;
	background-color: #fff;
	padding: 1rem;
	transition: box-shadow $transition-timing;
	border: 1px solid $color-gray-1;
	gap: 1rem;
	position: relative;
	cursor: pointer;

	&:hover {
		:global(.edit-overlay) {
			animation-name: fadeIn;
			z-index: 2;
		}
	}
}

.media {
	width: 100%;
	position: relative;
	border-radius: 1.25rem;

	.player {
		width: $player_icon-width;
		height: $player_icon-height;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate3d(-50%, -50%, 0);
		z-index: 2;
		transition: all $transition-timing;

		@include respond-below(md) {
			width: $player_icon_responsive-width;
			height: $player_icon_responsive-height;
		}

		&:hover {
			width: calc($player_icon-width + 1rem);
			height: calc($player_icon-height + 1rem);
		}
	}

	&:hover {
		.image {
			transform: scale(1.05);

			&Wrapper {
				&::before {
					opacity: 1;
				}
			}
		}
	}
}

.image {
	width: 100%;
	height: 100%;
	aspect-ratio: 16/9;
	object-fit: cover;
	margin: 0;
	background-color: $color-gray-1;
	border-radius: 1.25rem;
	transition: transform $transition-timing;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;

	&Wrapper {
		width: 100%;
		height: 100%;
		display: block;
		aspect-ratio: 16/9;
		border-radius: 1.25rem;
		overflow: hidden;

		&::before {
			content: '';
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			z-index: 0;
			border-radius: 1.25rem;
			background-color: rgba($color-gray-5, 0.4);
			opacity: 0;
			transition: opacity $transition-timing;
		}
	}
}

// .header {
// 	border-bottom: 1px solid $color-gray-2;
// 	margin-bottom: 15px;
// 	padding-bottom: 15px;
// }

// .title {
// 	-webkit-line-clamp: 2;
// 	overflow: hidden;
// 	display: -webkit-box;
// 	-webkit-box-orient: vertical;
// }

// .image {
// 	width: 100%;
// 	aspect-ratio: 16/9;
// 	object-fit: cover;
// 	border-radius: 20px 20px 0px 20px;

// 	&Wrapper {
// 		margin-bottom: 1rem;
// 		min-height: 150px;
// 		border-radius: 20px 20px 0px 20px;
// 	}
// }

// .text {
// 	-webkit-line-clamp: 4;
// 	overflow: hidden;
// 	display: -webkit-box;
// 	-webkit-box-orient: vertical;

// 	>p {
// 		margin-bottom: 0;
// 	}
// }