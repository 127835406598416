@import '../../modules';

.item {
    position: relative;
    min-height: 35px;

    @include respond-above(sm) {
        margin-bottom: 25px;
    }

    &:not(:last-child) {
        flex: 1;

        @include respond-above(sm) {
            min-height: 100px;
        }

        &:after {
            content: '';
            position: absolute;
            background-color: $color-primary-light;
            border-radius: 10px;
            top: 20px;
            height: 2px;
            width: calc(100% - 60px);
            left: 50px;

            @include respond-above(sm) {
                left: 25px;
                width: 2px;
                height: calc(100% - 50px);
                top: 65px;
            }

            @include respond-above(md) {
                left: 27px;
            }
        }
    }
}

.number {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    border-radius: 50%;
    font-weight: 700;
    font-size: 20px;
    color: $color-gray-3;
    box-shadow: 0 10px 20px 0 rgba($color-gray-5, 0.1),
        inset 0 10px 20px 0 rgba($color-primary, 0.3);
    border: 2px solid #fff;
    background-color: #fff;

    .active & {
        color: #fff;
        box-shadow: 0 10px 20px rgba($color-gray-5, 0.1),
            inset 0 10px 20px $color-secondary-dark;
        border-color: #ffdabb;
        background-color: $color-secondary-light;
    }

    .checked & {
        color: $color-primary-dark;
        box-shadow: 0 10px 20px 0 rgba(64, 62, 80, 0.1),
            inset 0 10px 20px 0 $color-tertiary-dark;
        border-color: $color-tertiary-light;
        background-color: $color-tertiary;
    }

    @include respond-above(sm) {
        width: 50px;
        height: 50px;
    }

    @include respond-above(md) {
        width: 55px;
        height: 55px;
    }
}

.content {
    padding-left: 1rem;

    @include respond-below(sm) {
        display: none;
    }
}

.title {
    margin-bottom: 0.5rem;
}