@import '../modules';

.dropdown {
	&-menu {
		font-size: 14px !important;
		border: none !important;
		margin-top: 0.5rem !important;
		border-radius: 10px !important;
		box-shadow: 0 5px 10px $box-shadow-color !important;
		z-index: 100 !important;
	}

	&-toggle {
		cursor: pointer;
		display: flex !important;
		align-items: center;
		color: $body_color !important;
		background-color: #fff;
		font-size: 14px !important;
		padding: 0.25rem 1rem;
		height: 40px !important;
		border-radius: 10px !important;
		box-shadow: 0 5px 10px $box-shadow-color !important;

		&:after {
			margin-left: 0.5rem;
		}
	}

	&-item {
		font-size: 13px !important;
		padding: 0.25rem 1rem !important;
		min-height: 35px;

		&:focus,
		&:active,
		&:hover {
			background-color: $color-gray-1 !important;
			color: $color-gray-5;
		}
	}

	&-divider {
		border-top-color: $border_color !important;
	}
}