@import '../modules';

.react-select {
    &--is-disabled {
        opacity: 0.6;
        background-color: $color-gray-2;
        box-shadow: none;
        border-color: transparent;
        color: $color-gray-3;
    }

    &__input {
        position: absolute !important;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        height: 100%;
        border-radius: $input-border-radius;
        padding-left: 18px !important;
    }

    &__value-container--is-multi {
        padding-bottom: 0 !important;
    }
}