@import '../../../modules';
@import '../../../core/animation';

.slide {
    position: relative;
    height: 100%;

    :global(.edit-overlay) {
        border-radius: 30px;

        &:hover {
            animation-name: fadeIn;
        }
    }
}

.loading {
    border-radius: 30px;
}