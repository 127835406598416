@import '../modules';

.button {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	font-weight: 400;
	border: 1px solid transparent;
	border-radius: $border-radius-10;
	text-align: center;
	transition: all $transition-timing;
	line-height: 1.35;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;

	&_sm {
		padding: 0.25rem 0.75rem;
		font-size: 13px;
		min-height: 2.25rem;
	}

	&_md {
		padding: 0.5rem 1rem;
		font-size: 14px;
		min-height: 2.5rem;
	}

	&_lg {
		padding: 1rem 2rem;
		font-size: 16px;
		min-height: 2.75rem;
	}
}

.hero {
	&_box {
		position: relative;
		margin-bottom: $section_mb - 30;

		@include respond-above(xl) {
			margin-bottom: $section_mb;
		}

		&:before {
			content: '';
			position: absolute;
			right: 0;
			left: 0;
			top: 0;
			z-index: 1;
			height: 155px;
			background: -moz-linear-gradient(top, rgba($color-gray-5, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
			background: -webkit-linear-gradient(top, rgba($color-gray-5, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
			background: linear-gradient(to bottom, rgba($color-gray-5, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
		}

		&:after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: -2;
			bottom: 0;
			background-color: $color-gray-1;
		}
	}

	&_decoration {
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		z-index: -1;
		overflow: hidden;

		&_top {
			height: 50vw;
			max-height: 450px;
			position: relative;
		}

		&_bottom {
			height: 200px;
			background-color: $color-gray-1;
			position: relative;
		}

		&_divider {
			position: absolute;
			bottom: -2px;
			left: -2px;
			right: -2px;

			&_line {
				position: absolute;
				bottom: 20px;
				left: 0;
				right: 0;
			}
		}
	}

	&_header {
		background-color: #fff;
		padding-top: $section_mb - 60;

		@include respond-above(md) {
			padding-top: $section_mb - 30;
		}

		&_image {
			width: 100%;
			position: relative;
			height: 55vw;
			max-height: 520px;
			overflow: hidden;

			@include respond-above(md) {
				min-height: 350px;
			}
		}

		&_logo {
			margin-bottom: 25px;
			flex-shrink: 0;

			@include respond-above(md) {
				margin-right: 30px;
				margin-bottom: 0;
			}
		}
	}

	&_image {
		background-color: $color-gray-1;
		object-fit: cover;
		aspect-ratio: 16/9;
		width: 100%;
		height: 100%;
		border-radius: 25px;
		box-shadow: 0 5px 20px 0 $box-shadow-color;
	}

	&_logo {
		width: 100%;
		max-width: 145px;
		object-fit: contain;
		margin-left: 0;

		@include respond-above(lg) {
			max-width: 180px;
		}
	}

	&_content {
		padding-top: $header_height_desktop;

		@include respond-above(sm) {
			padding-top: $header_height_desktop + 30;
		}
	}

	&_title {
		margin-bottom: 15px;
	}

	&_slogan {
		margin-bottom: 15px;
		font-size: 18px;
	}

	&_text {
		font-size: 16px;

		@include respond-below(sm) {
			font-size: 14px;
		}
	}
}