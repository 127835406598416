@import '../../../modules';

.button {
    composes: button from '../../common.scss';
    border-radius: 20px;
    padding: 3px 12px;
    min-height: 35px;
    color: $color-gray-4;
    border-color: $color-gray-3;
    margin-bottom: 0.5rem;
    justify-content: flex-start;
    text-align: left;

    &:hover {
        background-color: $color-gray-1;
    }

    &:not(:last-child) {
        margin-right: 0.5rem;
    }
}

.active {
    color: #fff !important;
    border-color: $color-primary-light !important;
    background-color: $color-primary-light !important;
    box-shadow: 0 5px 10px rgba($color-gray-5, 0.15);
}