.boxMessage {
	display: flex;
	align-items: center;
	gap: 2px;
	background: linear-gradient(180deg, #efeff2 0%, #f8f8f9 100%);
	padding: 16px;
	gap: 24px;
	border-radius: 8px;
	width: 100%;
}


.disabledCard {
	opacity: 30%;
	cursor: default !important;
}

.cardUpgradable {
	height: 130px;
	padding: 10px 16px;
	gap: 10px;
	border-radius: 16px;
	border: 1px;
	border: 1px solid #e7e7ec;
}

.addCardUpgradable {
	height: 120px;
	background: linear-gradient(180deg, #efeff2 0%, #f8f8f9 100%);
	padding: 16px 16px;
	border-radius: 10px;
	cursor: pointer;
}


.CardPlan {
	box-shadow: 0px 1px 15px 0px #0000001a;
	padding: 16px;
	border-radius: 16px;
}

.CardPlanSelected {
	background: #96bd6833;
}


.inputSearch {
	background-color: transparent !important;
	height: 100%;
	flex: 1;
	padding-right: 15px;
	padding-left: 15px;
}

.submitSearch {
	width: 40px;
}

.description {
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

@keyframes skeleton-loading {
	to {
		background-position: left;
	}
}

.cardPlanSkeleton {
	background: linear-gradient(
			90deg,
			rgba(238, 236, 236, 0.667) 40%,
			#ffffffaa,
			rgba(238, 236, 236, 0.667) 60%
		)
		right / 300% 100%;
	animation: skeleton-loading 2.5s linear infinite;
	height: 100px;
	border-radius: 10px;
}
