@import '../../modules';

$logo-size: 50px;

.card {
	padding: 1rem;
	height: 100%;
}

.table {
	height: 100%;

	@include respond-above(sm) {
		padding: 20px;
	}
}

.logo {
	width: 100%;
	height: 100%;
	object-fit: contain;
	border-radius: 50px;
	margin-left: 0;
	padding: 2px;

	&Box {
		width: $logo-size;
		height: $logo-size;
		margin-right: 1rem;
		border-radius: 50px;
		overflow: hidden;
		display: block;
		flex-shrink: 0;
		box-shadow: 0 3px 6px $box-shadow-color;
	}
}

.scrollbar {
	max-height: 1130px;
}

.guestBar {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	gap: 0.5rem;
	background-color: $color-gray-1;
	border-radius: 10px;
	padding: 1rem;
}

.notifBar {
	display: flex;
	align-items: center;
	gap: 1rem;
	margin-bottom: 20px;
	border-radius: 10px;
	padding: 1rem;
	color: $color-secondary-dark;
	background-color: rgba($color-yellow, 0.2);
}
