@import '../../modules';

$image-size: 6.25rem;

.avatar {
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin: 0;
    border-radius: 50%;
    background-color: $color-gray-1;

    &Box {
        width: $image-size;
        height: $image-size;
        flex-shrink: 0;
        border-radius: 50%;
        // margin-right: 15px;
        display: block;
        overflow: hidden;
        box-shadow: 0 5px 10px $box-shadow-color;
    }
}

.submit {
    @include respond-above(sm) {
        width: 100%;
        &:not(:last-child) {
            margin-bottom: 15px;
        }
    }
}