@import '../../modules';

.loading {
	display: flex;
	align-items: center;
	justify-content: center;
}

.section_title {
	font-size: 28px !important;
	margin-bottom: 10px;
}

.section_text {
	font-size: 14px;
	line-height: 1.8em !important;
	font-weight: 300;

	@media (min-width: 768px) {
		font-size: 16px;
		margin-top: 15px;
	}
}

.headline {
	margin-bottom: 45px;
	max-width: 680px;
}

.list {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	gap: 1.5rem;
}

.card {
	border: 1px solid $color-gray-1;
	border-radius: 20px;
	background-color: #fff;
	cursor: pointer;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	gap: 0.5rem;
	max-width: 100%;
	flex: 0 0 auto;
	overflow: visible !important;

	&:before {
		content: '';
		position: absolute;
		left: -1px;
		right: -1px;
		bottom: -1px;
		z-index: 0;
		height: 30%;
		border-radius: 20px;
		background: -moz-linear-gradient(
			top,
			rgba(#fff, 0) 0%,
			rgba($color-gray-2, 0.3) 100%
		);
		background: -webkit-linear-gradient(
			top,
			rgba(#fff, 0) 0%,
			rgba($color-gray-2, 0.3) 100%
		);
		background: linear-gradient(
			to bottom,
			rgba(#fff, 0) 0%,
			rgba($color-gray-2, 0.3) 100%
		);
		transition: height $transition-timing;
	}

	&:hover:before {
		height: 100%;
	}

	&_grid {
		width: 100%;
		@media (min-width: 576px) {
			width: calc(50% - 20px);
		}

		@media (min-width: 768px) {
			width: calc(33.33333333% - 20px);
		}

		@media (min-width: 992px) {
			width: calc(25% - 20px);
		}
	}

	&_logo {
		width: 100%;
		height: 100%;
		object-fit: contain;
		padding: 1.5rem;
		max-width: 80%;
		margin: 0 auto;
		border-radius: 20px 20px 0 0;
		mix-blend-mode: multiply;

		&_wrapper {
			width: 100%;
			display: block;
			position: relative;
			height: 11rem;
			// margin-bottom: 1rem;
			overflow: hidden;
			border-radius: 20px 20px 0 0;
			flex-grow: 1;
		}
	}

	&_content {
		flex: 1;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 0 10px 15px;
	}

	&_title {
		flex: 1;
		font-size: 16px;
		font-weight: 500;
		line-height: 1.6em;
		color: $color-gray-4;
		margin-top: 0;
		margin-bottom: 10px;
	}

	&_branch {
		color: $color-gray-4;
		line-height: 1.65em;
		font-weight: 300;
	}
}
