@import '../../modules';

.box {
    padding: 15px 20px;
    border-radius: 15px;
    margin-bottom: 20px;
}

.finished {
    background-color: $color-gray-2;
}

.canceled {
    background-color: rgba($color-red, 0.15);
}