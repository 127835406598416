@import '../../modules';

.card {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0.5rem;
	border-radius: 15px;
	height: 3.75rem;
	background-color: #fff;
	gap: 0.5rem;
	width: 100%;
}

.image {
	width: 3rem;
	height: 3rem;
	object-fit: contain;
	border-radius: 5px;
	padding: 2px;
	margin: 0;
	flex-shrink: 0;
}

.loading {
	height: 20px;
}