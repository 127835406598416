@import '../../modules';

.tab {
    &_link {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 16px;
        height: 50px;
        color: $color-gray-3;
        padding-right: 5px;
        padding-left: 5px;

        &:not(:last-child) {
            margin-right: 1rem;
        }

        &:before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            width: calc(100% - 10px);
            margin-right: auto;
            margin-left: auto;
            border-radius: 5px 5px 0 0;
            height: 6px;
            background-color: transparent;
            visibility: hidden;
        }

        &.active {
            color: $color-primary;
            font-weight: 500;

            &:before {
                background-color: $color-primary;
                visibility: visible;
            }
        }
    }

    &_list {
        white-space: nowrap;

        &_item {
            border-radius: 25px;
            padding-right: 15px;
            padding-left: 15px;
            min-height: 40px;
            background-color: #fff;
            box-shadow: 0 5px 10px $box-shadow-color;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;

            &.active {
                background-color: $color-primary !important;
                color: #fff !important;
                box-shadow: 0 5px 10px rgba($color-primary, 0.25);
            }
        }
    }
}