@import '../../../modules';

.card {
	border: 1px solid $color-gray-1;
	border-radius: 20px;
	background-color: #fff;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	height: 100%;
	gap: 0.5rem;
	position: relative;
	overflow: hidden;

	&:hover {
		&:before {
			height: 100%;
		}
	}

	&:before {
		content: '';
		position: absolute;
		left: -1px;
		right: -1px;
		bottom: -1px;
		z-index: 0;
		height: 30%;
		border-radius: 20px;
		background: -moz-linear-gradient(
			top,
			rgba(#fff, 0) 0%,
			rgba($color-gray-2, 0.3) 100%
		);
		background: -webkit-linear-gradient(
			top,
			rgba(#fff, 0) 0%,
			rgba($color-gray-2, 0.3) 100%
		);
		background: linear-gradient(
			to bottom,
			rgba(#fff, 0) 0%,
			rgba($color-gray-2, 0.3) 100%
		);
		transition: height $transition-timing;
	}
}

.logo {
	width: 100%;
	height: 100%;
	object-fit: contain;
	padding: 2rem;
	max-width: 80%;
	margin: 0 auto;
	border-radius: 20px 20px 0 0;
	mix-blend-mode: multiply;

	&Wrapper {
		width: 100%;
		display: block;
		position: relative;
		height: 12.5rem;
		margin-bottom: 1rem;
		overflow: hidden;
		border-radius: 20px 20px 0 0;
	}
}
