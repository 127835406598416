@import '../../modules';

.form {
    padding-bottom: 60px;
}

.sticky {
    &_action {
        position: fixed !important;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 19;
        margin-bottom: 0 !important;
        border-radius: 0 !important;
        box-shadow: 0 -5px 15px $box-shadow-color !important;
    }

    &_container {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 10px;
    }
}

.danger {
    background-color: rgba($color-red, 0.15) !important;
    box-shadow: 0 5px 10px rgba($color-red, 0.1) !important;
}