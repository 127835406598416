@import '../../modules';

.image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 15px;

    &Wrapper {
        width: 100%;
        // height: 370px;
        position: relative;
        border-radius: 15px;
        margin-bottom: 20px;
    }
}

.content {
    margin-top: 15px;
    margin-bottom: 30px;
    line-height: 1.8em;
}