@import '../../../../../../../styles/modules/';

$title-font-size: 1.125rem; // 18px
$gap-large: 1.25rem; // 20px
$padding: 1rem; // 16px
$margin-top: 0.625rem; // 10px
$gap-medium: 0.8125rem; // 13px
$logo-size: 3.125rem; // 50px
$font-size-small: 0.875rem; // 14px
$max-height: 18.75rem; // 300px

.title {
	font-size: $title-font-size;
	font-weight: 500;
}

.find-partner {
	display: flex;
	flex-direction: column;
	gap: $gap-large;
	width: 100%;
	background: $color-gray-1;
	border-radius: 0.5rem; // 8px
	padding: $padding;

	span {
		font-size: $font-size-small;
		font-weight: 500;
		color: $color-gray-4;
	}
}

.subscription-card-wrapper {
	margin-top: $margin-top;
	display: flex;
	flex-direction: column;
	gap: $gap-large;
	max-height: $max-height;
	overflow-y: auto;
}

.subscription-card {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: $gap-medium;
	padding-bottom: 10px;
	border-bottom: 1px solid $color-gray-7;
}

.subscription-logo {
	width: $logo-size;
	height: $logo-size;
	border-radius: 50%;
	object-fit: cover;
}
