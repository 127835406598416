@import '../../modules';

.publishbar {
	background-color: rgba($color-green, 0.3);
	box-shadow: 0 5px 10px rgba($color-green, 0.05);
	border-radius: 15px;
	margin-bottom: 20px;
	padding: 15px 20px;
}

.action {
	width: 40px;
	padding: 0;
	margin-right: 10px;
	flex-shrink: 0;
}

.downloadPdf {
	composes: button from '../common.scss';
	color: $color-primary !important;
	box-shadow: 0 5px 10px rgba($color-primary, 0.2);
	background-color: #fff;
	cursor: pointer;

	&:hover {
		background-color: $color-primary !important;
		color: #fff !important;
	}
}

.submit {
	min-height: 45px;
}

@keyframes skeleton-loading {
	to {
		background-position: left;
	}
}

.titleCategories {
	padding: 1rem;
	position: relative;
	display: flex;
	width: 100%;
	align-items: center;
	min-height: 20px;
	background: linear-gradient(90deg, #ddda 40%, #efefefaa, #ddda 60%) right /
		300% 100%;
	animation: skeleton-loading 2.5s linear infinite;

	&:not(:last-of-type) {
		border-bottom: 1px solid $color-gray-1;
		padding-bottom: 10px;
		margin-bottom: 10px;
	}
}

.titleJobTags {
	padding: 1rem;
	position: relative;
	display: flex;
	width: 100px;
	align-items: center;
	min-height: 40px;
	background: linear-gradient(90deg, #ddda 40%, #efefefaa, #ddda 60%) right /
		300% 100%;
	animation: skeleton-loading 2.5s linear infinite;

	&:not(:last-of-type) {
		border-bottom: 1px solid $color-gray-1;
		padding-bottom: 10px;
		margin-bottom: 10px;
	}
}

.inputSearch {
	background-color: transparent !important;
	height: 100%;
	flex: 1;
	padding-right: 15px;
	padding-left: 15px;
}

.submitSearch {
	width: 40px;
}

.boxTag {
	height: 41px;
	border-radius: 8px;
	white-space: 'nowrap';
	background: linear-gradient(180deg, #efeff2 0%, #f8f8f9 100%);
}

.boxTagDisabled {
	height: 41px;
	border-radius: 8px;
	background: #ffeacc;
}
