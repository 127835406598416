@import '../../../modules';

.image {
	width: 100%;
	object-fit: cover;
	aspect-ratio: 16/9;
	border-radius: 20px;
	background-color: $color-gray-1;

	&Wrapper {
		border-radius: 20px;
		aspect-ratio: 16/9;
		background: #fff;
	}
}

.video {
	position: relative;
	cursor: pointer;

	&::after {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background-color: rgba($color-gray-4, 0.4);
		border-radius: 20px;
		opacity: 0;
	}

	&:hover .play_icon {
		transform: translate(-50%, -50%) scale(1.2);
	}
}

.play_icon {
	position: absolute;
	top: 50%;
	left: 50%;
	transition: all 0.4s;
	transform: translate(-50%, -50%);
	cursor: pointer;
	z-index: 1;
}
