@import '../../../modules';

.content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: $overlay-content-z-index;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(#fff, 0.8);
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: $overlay-z-index;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    min-width: 100vh;
    background-color: #fff;
}