@import '../../modules';

.logo {
    width: 100%;
    height: 100%;
    object-fit: contain;
    margin-left: 0;
    border-radius: 10px;
    padding: 3px;

    &Wrapper {
        max-width: 160px;
        height: 80px;
        min-width: 80px;
        border-radius: 15px;
        flex-shrink: 0;
        margin-right: 20px;
        border-radius: 10px;
        border: 1px solid $color-gray-1;
    }
}