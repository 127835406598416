@import '../../modules';

$logo-size: 50px;

.card {
    display: flex;
    align-items: center;
    min-height: 85px;

    &:not(:last-of-type) {
        border-bottom: 1px solid $color-gray-1;
        padding-bottom: 10px;
        margin-bottom: 10px;
    }
}

.row {
    display: flex;
    align-items: center;
    min-height: 1rem;
    margin-bottom: 0.5rem;
    flex-wrap: wrap;

    &Label {
        padding-right: 0.5rem;
    }

    @include respond-below(xs) {
        justify-content: space-between;
    }

    @include respond-below(sm) {
        padding-bottom: 0.5rem;
        border-bottom: 1px solid $color-gray-1;

        &:last-child {
            border-bottom: 0;
            margin-bottom: 1.25rem;
        }
    }
}


.logo {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 50px;
    margin-left: 0;
    padding: 2px;

    &Box {
        width: $logo-size;
        height: $logo-size;
        margin-right: 15px;
        border-radius: 50px;
        overflow: hidden;
        display: block;
        flex-shrink: 0;
        box-shadow: 0 3px 6px $box-shadow-color;
    }
}

.loading {
    &Col {
        height: 20px;

        &:not(:last-child) {
            margin-bottom: 10px;
        }

        &Lg {
            width: 150px;
        }

        &Sm {
            width: 90px;
        }
    }
}

.boxPrice {
    border: 1px solid $color-gray-1;
    padding: 16px;
    margin-bottom: 1rem;
    border-radius: 8px;
}