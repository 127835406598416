@import '../../modules';

.loading {
	display: flex;
	align-items: center;
	justify-content: center;
}

.section_title {
	font-size: 28px !important;
	margin-bottom: 10px;
}

.headline {
	margin-bottom: 45px;
	max-width: 680px;
}

.container {
	margin-top: 30px;
	margin-bottom: 30px;
}

.list {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
	gap: 20px;
}

.card {
	background-color: white;
	border-radius: 15px;
	box-shadow: 0 5px 15px 0 $box-shadow-color;
	padding: 15px;
	display: flex;
	flex-direction: column;
	height: 100%;

	&:hover {
		.title {
			color: $color-primary;
		}
	}
}

.header {
	display: flex;
	flex-direction: column-reverse;
	flex-grow: 1;
}

.content {
	display: flex;
	flex-direction: column;
	flex-grow: 1;

	@include respond-below(sm) {
		margin-bottom: 10px;
		padding-bottom: 10px;
		border-bottom: 1px solid $color-gray-1;
	}

	@include respond-above(sm) {
		margin-left: 15px;
	}
}

.image {
	object-fit: contain;
	border-radius: 10px;
	padding: 5px;
	width: 100%;
	height: 100%;

	&Box {
		outline: solid 1px $color-gray-1;
		flex-shrink: 0;
		overflow: hidden;
		border-radius: 10px;
		width: 50px;
		height: 50px;

		@include respond-above(sm) {
			width: 70px;
			height: 70px;
		}

		@include respond-above(md) {
			width: 95px;
			height: 95px;
		}
	}
}
.footer {
	border-top: 1px solid $color-gray-1;
	padding-top: 10px;
	margin-top: 15px;
	color: $color-gray-3;
}

.mobile_company {
	overflow: hidden;
	margin-left: 15px;
	flex-grow: 1;
	color: $color-gray-3;

	@include respond-above(sm) {
		display: none !important;
	}
}

.title {
	font-size: 16px;
	line-height: 1.45em;
	font-weight: 500;
	margin-bottom: 5px;
	transition: color $transition-timing;
}

.type {
	color: $color-gray-3;
	font-size: 14px;
	display: flex;
}

.location {
	font-size: 13px;
	line-height: 1;
	font-weight: 300;
}
