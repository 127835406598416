@import '../../modules';

.wrapper {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}
.headerEditProfile {
     margin-top: 210px;

	 @include respond-above(sm) {
		margin-top: 20px;
    }
}
