@import '../../modules/';

.features {
	display: grid;
	grid-template-columns: 1fr;
	gap: 30px;
	@media only screen and (min-width: 800px) {
		grid-template-columns: repeat(2, 1fr);
	}

	@media only screen and (min-width: 1200px) {
		grid-template-columns: repeat(3, 1fr);
	}
}
.gallery {
	width: 100%;
	display: grid;
	grid-template-columns: 1fr;
	gap: 15px;

	@media only screen and (min-width: 800px) {
		grid-template-columns: repeat(2, 1fr);
	}

	@media only screen and (min-width: 1200px) {
		grid-template-columns: repeat(3, 1fr);
	}
}
.drawer {
	transition: height 0.3s ease;
	position: absolute;
	top: 0;
	z-index: 10;
	right: 0;
	height: 100%;
	width: 100%;
}

@media only screen and (min-width: 2000px) {
	.drawer {
		width: 60%;
	}

	.gap150 {
		gap: 150px;
	}
}

@media only screen and (min-width: 800px) {
	.gap150 {
		gap: 70px;
	}

	.drawer {
		width: 100%;
	}

	.buttonClose {
		top: -18px !important;
		// left: -20px !important;
	}
}

.drawer-content {
	height: 100%;
	// left: 196px;
	overflow-y: auto;
	overflow-x: hidden;
	// border-radius: 30px 0px 0px 30px;
	border: 1px;
	background: #00000080;
	border: 1px solid #545454;
	backdrop-filter: blur(40px);
	padding: 40px 80px !important;

	@include respond-below(lg) {
		border-radius: 0%;
		padding: 40px 20px !important;
	}
}

.buttonClose {
	position: absolute;
	z-index: 101;
	top: -20px;
	left: 50%;
	transform: translateX(-50%);
}

.gap150 {
	gap: '20px';
}
