@import '../../../../../../../styles/modules/';

.cardLocation {
	height: 120px;
	padding: 10px 16px;
	gap: 10px;
	border-radius: 16px;
	border: 1px;
	border: 1px solid #e7e7ec;
}

.addCardLocation {
	height: 120px;
	display: flex;
	align-items: center;
	background: linear-gradient(180deg, #efeff2 0%, #f8f8f9 100%);
	padding: 10px 16px;
	border-radius: 10px;
	cursor: pointer;
}

.CardBranch {
	box-shadow: 0px 1px 15px 0px #0000001a;
	padding: 16px;
	border-radius: 16px;
}

.CardBranchSelected {
	background: #96bd6833;
}

.inputSearch {
	background-color: transparent !important;
	height: 100%;
	flex: 1;
	padding-right: 15px;
	padding-left: 15px;
}

.submitSearch {
	width: 40px;
}

.description {
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

@keyframes skeleton-loading {
	to {
		background-position: left;
	}
}

.cardBranchSkeleton {
	background: linear-gradient(
			90deg,
			rgba(238, 236, 236, 0.667) 40%,
			#ffffffaa,
			rgba(238, 236, 236, 0.667) 60%
		)
		right / 300% 100%;
	animation: skeleton-loading 2.5s linear infinite;
	height: 150px;
	border-radius: 10px;
}
