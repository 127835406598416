@import '../../modules';

$logo-size: 50px;

.card {
    min-height: 85px;

    &:not(:last-of-type) {
        border-bottom: 1px solid $color-gray-1;
        margin-bottom: 15px;
    }
}

.logo {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 50px;
    margin-left: 0;
    padding: 2px;

    &Box {
        width: $logo-size;
        height: $logo-size;
        margin-right: 15px;
        border-radius: 50px;
        overflow: hidden;
        display: block;
        flex-shrink: 0;
        box-shadow: 0 3px 6px $box-shadow-color;
    }
}

.meta {
    padding-top: 10px;
    padding-bottom: 15px;
    margin-top: 5px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.loading {
    &Col {
        height: 20px;

        &:not(:last-child) {
            margin-bottom: 10px;
        }

        &Lg {
            width: 180px;
        }

        &Md {
            width: 100px;
        }

        &Sm {
            width: 65px;
        }
    }
}