@import '../../../modules';
@import '../../../core/animation';
$header-height: 55px;

.box {
	padding: 1.25rem 1rem;
	position: relative;
	cursor: pointer;
	height: 100%;

	&:hover {
		:global(.edit-overlay) {
			animation-name: fadeIn;
		}
	}
}

.boxDisabled {
	padding: 1.25rem 1rem;
	position: relative;
	cursor: default;
	height: 100%;
}

.borderBox {
	border-bottom: 1px solid rgba(#fff, 0.3);

	@include respond-above(xs) {
		border-right: 1px solid rgba(#fff, 0.3) !important;

		&:nth-child(2n) {
			border-right: 0 !important;
		}
	}

	@include respond-above(md) {
		&:nth-child(2n) {
			border-right: 1px solid rgba(#fff, 0.3) !important;
		}

		&:nth-child(3n) {
			border-right: 0 !important;
		}
	}

	@include respond-above(xl) {
		&:nth-child(2n) {
			border-right: 1px solid rgba(#fff, 0.3) !important;
		}

		&:nth-child(3n) {
			border-right: 1px solid rgba(#fff, 0.3) !important;
		}

		&:nth-child(4n) {
			border-right: 0 !important;
		}
	}
}

.icon {
	width: 3.75rem;
	height: 3.75rem;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50px;
	border: 5px solid;
	flex-shrink: 0;
}