@import '../../../modules';

.card {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	height: 100%;
	position: relative;
	overflow: hidden;

	&:hover {
		&:before {
			height: 100%;
		}
	}

	&:before {
		content: '';
		position: absolute;
		left: -1px;
		right: -1px;
		bottom: -1px;
		z-index: 0;
		transition: height $transition-timing;
	}
}
.logo {
	width: 100%;
	height: 100%;
	object-fit: contain;
	max-width: 50%;
	margin: 0 auto;
	mix-blend-mode: multiply;

	&Wrapper {
		width: 100%;
		display: block;
		position: relative;
		height: 7.5rem;
		overflow: hidden;
		border-radius: 20px 20px 0 0;
	}
}
