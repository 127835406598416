@import '../../modules';

.image {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    height: 100%;
    
    &Wrapper {
        display: inline-flex;
        padding: 10px 15px;
        border-radius: 5px;
        width: 207px;
        height: 71px;

        &_white {
            background-color: $color-gray-5;
        }
    }
}