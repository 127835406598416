.card {
	background: #ffffff;
	cursor: pointer;
	border-radius: 8px;
	padding: 16px;
	box-shadow: 0 2px 14px rgba(91, 91, 91, 0.1);
	font-family: Arial, sans-serif;
	display: flex;
	flex-direction: column;
	gap: 12px;

	&--selected {
		cursor: not-allowed;
		background: #f3f3f3;
	}
}
.header {
	display: flex;
	align-items: center;
	justify-content: start;
	gap: 12px;

	.logo {
		width: 50px;
		height: 50px;
		border-radius: 50%;
		object-fit: cover;
	}

	.title {
		font-size: 16px;
		font-weight: bold;
		margin: 0;
		color: #333;
	}
	.subtitle {
		font-size: 14px;
		color: #666;
		margin: 0;
	}
}

.info {
	display: flex;
	flex-direction: column;
	gap: 4px;
	font-size: 14px;
	color: #444;
	.region {
		font-weight: 400;
	}

	.address {
		color: #666;
	}
}
