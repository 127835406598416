@import '../../../../../../../styles/modules/';
$title-font-size: 1.125rem; // 18px
$gap-large: 1.25rem; // 20px
$padding: 1rem; // 16px
$margin-top: 2rem; // 10px
$gap-medium: 0.8125rem; // 13px
$logo-size: 3.125rem; // 50px
$font-size-small: 0.875rem; // 14px
$max-height: 18.75rem; // 300px

.title {
	font-size: $title-font-size;
	font-weight: 500;
}

.find-partner {
	display: flex;
	flex-direction: column;
	gap: $gap-large;
	width: 100%;
	background: $color-gray-1;
	border-radius: 0.5rem; // 8px
	padding: $padding;
	span {
		font-size: $font-size-small;
		font-weight: 500;
		color: $color-gray-4;
	}
}

.premium-card-wrapper {
	padding: 10px;
	margin-top: $margin-top;
	display: flex;
	flex-direction: column;
	gap: $gap-large;
	max-height: $max-height;
	overflow-y: auto;
}

.premium-card {
	width: 100%;
	display: flex;
	cursor: pointer;
	justify-content: baseline;
	flex-direction: column;
	align-items: baseline;
	gap: $gap-medium;
	background-color: #ffffff;
	border-radius: 12px;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
	padding: 16px;
	font-family: Arial, sans-serif;

	&--checked {
		background-color: #96bd6833;
		color: $color-gray-4;
	}

	&--disabled {
		background-color: $color-gray-1;
		color: $color-gray-4;
		cursor: not-allowed;
	}

	&--link {
		text-decoration: underline;
		white-space: nowrap;
		color: black;
		font-weight: 700;
	}
}

.premium-logo {
	width: $logo-size;
	height: $logo-size;
	border-radius: 50%;
	object-fit: cover;
}
