@import '../modules';

.custom-switch {
	display: block !important;
	background: $color-gray-2 !important;
	border: none !important;

	&:focus {
		box-shadow: initial !important;
	}

	&:after {
		margin-left: 2px !important;
		box-shadow: none !important;
		transform: initial !important;
		animation-name: unset !important;
		background: #fff !important;
	}

	&-sm {
		width: 44px !important;
		height: 23px !important;

		&:after {
			width: 17px !important;
			height: 17px !important;
			left: 2px !important;
			top: 3px !important;
		}
	}

	&-md {
		width: 51px !important;
		height: 29px !important;

		&:after {
			width: 21px !important;
			height: 21px !important;
			left: 2px !important;
			top: 4px !important;
		}
	}

	&-primary {
		&.rc-switch-checked {
			background: $color-primary !important;
			border: none !important;
		}
	}
}

.rc-switch-checked.custom-switch {
	&:after {
		box-shadow: none !important;
	}

	&-sm:after {
		left: 22px !important;
	}

	&-md:after {
		left: 23px !important;
	}
}