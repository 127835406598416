@import '../../modules';
@import '../../core/animation';

$image-size-mobile: 6rem;
$image-size-desktop: 7.5rem;

.avatar {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 50%;
    display: block;

    &Box {
        width: $image-size-mobile;
        height: $image-size-mobile;
        flex-shrink: 0;
        border-radius: 50%;
        margin-right: 1rem;
        box-shadow: 0 5px 10px $box-shadow-color;
        position: relative;

        &:hover {
            .edit {
                animation-name: fadeIn;
            }
        }

        @include respond-above(sm) {
            width: $image-size-desktop;
            height: $image-size-desktop;
        }
    }

}

.shimmer {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    margin-left: 0;
    background-color: #fff;
    object-fit: cover;
}

.edit {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: #fff;
    background-color: rgba($color-primary, 0.75);
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    top: 0;
    z-index: 1;
    border-radius: 50%;
    animation-name: fadeOut;
    animation-fill-mode: forwards;
    animation-duration: $transition-timing + 0.05;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 6;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: rgba($color-gray-5, 0.9);
}