@import '../../../modules';
@import '../../../core/animation';

.carousel {
    background-color: $color-gray-1;
    border-radius: 25px;
    border-bottom: 3px solid;
    padding: 1.5rem;

    @include respond-above(md) {
        overflow: initial !important;
        height: 400px;
    }
}

.slide {
    position: relative;
    height: unset;
}

.bullets {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 0.5rem;
    gap: 0.5rem;


    @include respond-above(md) {
        position: absolute;
        z-index: 21;
        right: 1.25rem !important;
        width: 10px !important;
        left: auto !important;
        bottom: auto !important;
        margin-bottom: 0;
        flex-direction: column;
    }
}

.bullet {
    display: flex;
    align-items: center;
    width: 10px;
    height: 10px;
    cursor: pointer;
    border-radius: 10px;
    transition: all $transition-timing;
    opacity: 0.2;
	background-color: black;

    &.active {
        opacity: 1;
        width: 25px;

        @include respond-above(md) {
            width: 10px;
            height: 25px;
        }
    }
}