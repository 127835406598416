@import '../../modules';
@import '../../core/animation';

.sidebar {
    position: fixed;
    left: 0;
    top: $header-height-mobile;
    bottom: 0;
    z-index: 100;
    color: #fff;
    background-color: $color-primary-dark;
    width: $sidebar-width;

    @include respond-above(md) {
        top: $header-height-desktop;
    }

    @include respond-below(md) {
        animation-fill-mode: both;
        animation-duration: $transition-timing + 0.05;

        &.open {
            animation-name: slideFromLeft;
        }

        &.close {
            animation-name: slideToLeft;
        }
    }
}

.list {
    padding: 1.5rem 1.5rem 1.5rem 1rem;
    display: flex;
    flex-direction: column;

    :global(.nav-item) {
        margin-bottom: 0.25rem;
    }
}

.scrollbar {
    // margin-bottom: 3rem;
    height: calc(100% - 3rem);
}

.support {
    height: 3rem;
    // background: $color-primary;
    // padding: 1rem;
}