@import '../../modules';
@import '../../core/animation';

.item {
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	width: 2.25rem;
	height: 2.25rem;
	position: relative;

	// &:hover {
	// 	background-color: rgba($color-gray-2, 0.7);

	// 	.remove {
	// 		animation-name: fadeIn;
	// 	}
	// }
}

.image {
	width: 100%;
	height: 100%;
}

.add {
	border-radius: 50%;
	background-color: #fff;
	border: 1px solid $color-gray-1;
	width: 35px;
	min-height: 35px !important;
	padding: 0;
	flex-shrink: 0;
	color: $color-primary;
	// box-shadow: 0 5px 10px rgba($color-gray-4, 0.05);
}

.remove {
	border-radius: 50%;
	width: 35px;
	min-height: 35px !important;
	padding: 0;
	flex-shrink: 0;
	color: $color-red;
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	background-color: rgba(#fff, 0.95);
	// box-shadow: 0 5px 10px rgba($color-gray-4, 0.05);
	animation-name: fadeOut;
	animation-fill-mode: forwards;
	animation-duration: $transition-timing + 0.05;
}
