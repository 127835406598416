@import '../../modules';

.businessPartner {
    display: flex;
    align-items: center;
    flex-grow: 1;
    &:not(:last-child) {
        margin-bottom: 15px;
    }
    &Logo {
        width: 100%;
        height: 100%;
        object-fit: contain;
        margin-left: 0;
        border-radius: 50px;
        flex-shrink: 0;
        
        // border: 1px solid $border-color;
        &Wrapper {
            overflow: hidden;
            margin-right: 15px;
            width: 50px;
            height: 50px;
            border-radius: 50px;
            box-shadow: 0 5px 10px $box-shadow-color;
        }
    }
}