@import '../../../modules';
@import '../../../core/animation.scss';

.card {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 75%;
    margin-right: auto;
    margin-left: auto;
    padding: 0.5rem;
    min-height: 400px;
    transition: $transition-timing;

    @include respond-above(xs) {
        width: 100%;
    }

    &:hover {
        cursor: pointer;

        :global(.edit-overlay) {
            animation-name: fadeIn;
        }
    }
}

.image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0px 15px 50px 0;
    border-bottom: 3px solid;

    &Wrapper {
        width: 100%;
        height: 100%;
        border-radius: 0px 15px 50px 0;
        position: relative;
        flex-grow: 1;
        display: flex;
        overflow: hidden;
    }
}

.position {
    position: absolute;
    bottom: 0;
    left: 0px;
    border-radius: 0px 10px 0px 0px;
    font-weight: 300;
    font-size: 14px;
    color: $color-gray-1;
    display: flex;
    align-items: center;
    line-height: 1.5;
    max-width: 80%;
    padding: 0.25rem 0.5rem;
}