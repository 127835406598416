@import '../../modules';

.selected {
	background-color: rgba($color-primary-light, 0.2) !important;
	outline: 1px solid $color-primary;
	box-shadow: 0 5px 10px rgba($color-primary, 0.1) !important;
}

.boxLogo {
	max-width: 215px;
	height: 150px;
	// background-color: #cecece33;
	display: flex;
	padding: 1rem 1rem;
	// align-items: center;
	object-fit: contain;
}

.logo {
	margin-left: 0;
	margin-right: 0;
	object-fit: contain;
	border-radius: 5px;

	&Wrapper {
		overflow: hidden;
		flex-shrink: 0;
		width: 100%;
		background-size: cover;
		border-radius: 5px;
	}
}

.image {
	width: 100%;
	// height: 165px;
	border-radius: 10px;
	object-fit: cover;
	background-color: $color-gray-1;
	aspect-ratio: 16/9;

	@include respond-above(lg) {
		margin-top: 0;
	}

	@include respond-above(xl) {
		max-height: 195px;
	}
}

.more {
	composes: button from '../common.scss';
	composes: button_md from '../common.scss';
	background-color: #fff;
	color: $color-gray-5 !important;
	box-shadow: 0 5px 10px rgba($color-gray-5, 0.2);

	&:hover {
		background-color: $color-gray-5 !important;
		color: #fff !important;
	}
}

.boxPlans {
	min-width: 132px;
	color: #4c5c84;
	background-color: #fff7e6;
	padding: 4px 8px 4px 8px;
	border-radius: 4px;
}

@keyframes pulse {
	0%,
	100% {
		opacity: 1;
	}
	50% {
		opacity: 0.5;
	}
}

.boxPlansSkeleton {
	width: 132px;
	height: 50px;
	background: #f0f0f0;
	border-radius: 4px;
	animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

.boxPlansPremium {
	min-width: 132px;
	color: #4c5c84;
	background-color: #e6edff;
	padding: 4px 8px 4px 8px;
	border-radius: 4px;
}


.notShadow{
	box-shadow: none !important;
	// border-bottom: 1px solid #E7E7EC;
	border-radius: 0px;
	padding-bottom: 2rem;
}
