// @import 'variables';

// Mixin to prefix several properties at once
// @author Hugo Giraudel
// @param {Map} $declarations - Declarations to prefix
// @param {List} $prefixes (()) - List of prefixes to print
@mixin prefix($declarations, $prefixes: ()) {
	@each $property, $value in $declarations {
		@each $prefix in $prefixes {
			#{'-' + $prefix + '-' + $property}: $value;
		}

		#{$property}: $value;
	}
}

// Gives a card depth effect.
// @param {Number} $depth - depth level (between 1 and 5)
// @link http://www.google.com/design/spec/layout/layout-principles.html#layout-principles-dimensionality Google Design
// @requires {function} top-shadow
// @requires {function} bottom-shadow
@mixin depth($depth) {
	@if $depth < 1 {
		box-shadow: none;
	} @else if $depth >5 {
		@warn "Invalid $depth `#{$depth}` for mixin `card`.";
	} @else {
		box-shadow: bottom-shadow($depth), top-shadow($depth);
	}
}

//Responsive Breakpoints
$breakpoints: (
	xxs: 420px,
	xs: 576px,
	sm: 768px,
	md: 992px,
	lg: 1200px,
	xl: 1400px,
	xxl: 1600px
);

@mixin respond-above($breakpoint) {
	// If the breakpoint exists in the map.
	@if map-has-key($breakpoints, $breakpoint) {
		// Get the breakpoint value.
		$breakpoint-value: map-get(
			$breakpoints,
			$breakpoint
		); // Write the media query.

		@media (min-width: ($breakpoint-value)) {
			@content;
		}

		// If the breakpoint doesn't exist in the map.
	} @else {
		// Log a warning.
		@warn "Invalid breakpoint: #{$breakpoint}.";
	}
}

@mixin respond-below($breakpoint) {
	// If the breakpoint exists in the map.
	@if map-has-key($breakpoints, $breakpoint) {
		// Get the breakpoint value.
		$breakpoint-value: map-get(
			$breakpoints,
			$breakpoint
		); // Write the media query.

		@media (max-width: ($breakpoint-value)) {
			@content;
		}

		// If the breakpoint doesn't exist in the map.
	} @else {
		// Log a warning.
		@warn "Invalid breakpoint: #{$breakpoint}.";
	}
}

@mixin animation($name, $timing: ease-out, $fill-mode: forwards) {
	-webkit-animation-name: $name;
	animation-name: $name;
	-webkit-animation-timing-function: $timing;
	animation-timing-function: $timing;
	-webkit-animation-fill-mode: $fill-mode;
	animation-fill-mode: $fill-mode;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}

/* # Text Underline Animation
------------------------------------------- */
@mixin underline($color, $offset: 0, $height: 2px) {
	position: relative;

	&:after {
		content: '';
		position: absolute;
		bottom: -$offset;
		left: 0;
		width: 100%;
		height: $height;
		transform: scaleX(0);
		background-color: $color;
		transform-origin: bottom right;
		transition: transform $transition-timing $transition-function;
	}

	&:hover:after {
		transform: scaleX(1);
		transform-origin: bottom left;
	}
}

/* # Input Placeholder
------------------------------------------- */
@mixin placeholder {
	&::-webkit-input-placeholder {
		@content;
	}

	&:-moz-placeholder {
		@content;
	}

	&::-moz-placeholder {
		@content;
	}

	&:-ms-input-placeholder {
		@content;
	}

	&:placeholder {
		@content;
	}
}

/* # Webkit Scrollbar
------------------------------------------- */
@mixin scrollbar($size, $foreground-color, $thumb-round: 30px) {
	$background-color: mix($foreground-color, #fff, 20%);

	&::-webkit-scrollbar {
		width: $size;
		height: $size;
	}

	&::-webkit-scrollbar-thumb {
		background: $foreground-color;
		border-radius: $thumb-round;
	}

	&::-webkit-scrollbar-track {
		background: $background-color;
	}
}

/* # Font Smoothing
------------------------------------------- */
@mixin font-smoothing {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
