@import '../../modules';

.header {
    display: flex;
    align-items: center;
    height: $header-height-mobile;
    background-color: $color-primary;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 101;
    width: 100%;
    box-shadow: 0 5px 20px $box-shadow-color;

    @include respond-above(md) {
        height: $header-height-desktop;
    }
}

.logo {
    background-color: rgba(#000, 0.1);
    // display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    // height: 100%;
    width: $sidebar-width;

    @include respond-above(md) {
        height: $header-height-desktop;
    }

    &_image {
        width: 130px;
        // height: 30px;
    }

    &_profile {
        display: flex;
        height: $header-height-mobile;

        @include respond-above(md) {
            height: $header-height-desktop;
        }

        &_image {
            max-width: 110px;
            height: 100%;
            object-fit: contain;
            padding-top: 15px;
            padding-bottom: 15px;
        }
    }
}

.navbar {
    flex-grow: 1;
    height: $header-height-mobile;
    background-color: #fff;
    display: flex;

    @include respond-above(md) {
        height: $header-height-desktop;
    }
}

.trigger {
    margin-right: 10px;
    color: $body_color;
    width: 40px;
}