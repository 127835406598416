@import 'variables';
@import '../../modules';

.#{$rt-namespace}__toast {
	position: relative;
	min-height: $rt-toast-min-height;
	max-height: $rt-toast-max-height;
	font-family: $rt-font-family;
	cursor: pointer;
	border-radius: $border-radius-10;

	&:not(:last-child) {
		margin-bottom: 15px;
	}

	.toast {
		background-color: #fff;
		border: none !important;
		border-radius: $border-radius-10;
		box-shadow: none !important;

		&-header {
			height: 45px;
			border-bottom: none !important;
			border-radius: $border-radius-10 $border-radius-10 0 0;
		}

		&-body {
			padding: 15px !important;
			border-radius: 0 0 $border-radius-10 $border-radius-10;
		}
	}

	&--dark {
		background: $rt-color-dark;
		color: $rt-text-color-dark;
	}

	&--default {
		background: $rt-color-default;
		color: $rt-text-color-default;
	}

	&--info {
		box-shadow: 0 5px 10px rgba($rt-color-info, 0.15) !important;

		.toast-header {
			color: #fff;
			background: $rt-color-info;
		}
	}

	&--success {
		box-shadow: 0 5px 10px rgba($rt-color-success, 0.15) !important;

		.toast-header {
			color: #fff;
			background: $rt-color-success;
		}
	}

	&--warning {
		box-shadow: 0 5px 10px rgba($rt-color-warning, 0.15) !important;

		.toast-header {
			background: $rt-color-warning;
		}
	}

	&--error {
		box-shadow: 0 5px 10px rgba($rt-color-error, 0.15) !important;

		.toast-header {
			color: #fff;
			background: $rt-color-error;
		}
	}
}

.#{$rt-namespace}--animate {
	animation-fill-mode: both;
}

.animate__slideIn {
	@include animation(fadeInTop);
	animation-duration: $transition_timing - 0.1;
}

.animate__slideOut {
	@include animation(fadeOutToBottom);
	animation-duration: $transition_timing - 0.15;
}

@-webkit-keyframes fadeInTop {
	from {
		-webkit-transform: translate3d(0, -8px, 0);
		transform: translate3d(0, -8px, 0);
		opacity: 0;
	}

	to {
		@include transform3d;
		opacity: 1;
	}
}

@keyframes fadeInTop {
	from {
		-webkit-transform: translate3d(0, -8px, 0);
		transform: translate3d(0, -8px, 0);
		opacity: 0;
	}

	to {
		@include transform3d;
		opacity: 1;
	}
}

@-webkit-keyframes fadeOutToTop {
	to {
		-webkit-transform: translate3d(0, -8px, 0);
		transform: translate3d(0, -8px, 0);
		opacity: 0;
	}
}

@keyframes fadeOutToTop {
	to {
		-webkit-transform: translate3d(0, -8px, 0);
		transform: translate3d(0, -8px, 0);
		opacity: 0;
	}
}

@-webkit-keyframes fadeOutToBottom {
	to {
		-webkit-transform: translate3d(0, 8px, 0);
		transform: translate3d(0, 8px, 0);
		opacity: 0;
	}
}

@keyframes fadeOutToBottom {
	to {
		-webkit-transform: translate3d(0, 8px, 0);
		transform: translate3d(0, 8px, 0);
		opacity: 0;
	}
}