@import '../../modules';
@import '../../core/animation';

$image-width-desktop: 300px;
$image-height-desktop: 180px;
$image-width-tablet: 250px;
$image-height-tablet: 160px;
$image-width-mobile: 180px;
$image-height-mobile: 110px;

.video {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 10px;
	box-shadow: 0 5px 10px $box-shadow-color;

	&Wrapper {
		width: 100%;
		height: 100%;
		border-radius: 10px;
	}

	&Uploader {
		background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23D5D5DBFF' stroke-width='2' stroke-dasharray='5' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
		background-color: rgba($color-gray-1, 0.5);
		border-radius: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		text-align: center;
		padding: 20px;
		color: $color-primary;
		min-height: 250px;
		cursor: pointer;
		position: relative;
	}
}

.feature {
	width: 100%;
	height: 100%;
	border-radius: 10px;
	overflow: hidden;
	position: relative;
	flex-shrink: 0;

	@include respond-above(sm) {
		width: $image-width-desktop;
	}

	&Box {
		overflow: hidden;
		display: block;
		border-radius: 10px;
	}

	&:hover {
		.edit {
			animation-name: fadeIn;
		}
	}
}

.shimmer {
	border-radius: 10px;
	width: 100%;
	aspect-ratio: 16/9;
	object-fit: cover;
	overflow: hidden;
	background-color: $color-gray-1;
}

.edit {
	position: absolute;
	right: 0;
	left: 0;
	bottom: 0;
	top: 0;
	z-index: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	color: #fff;
	background-color: rgba($color-primary, 0.75);
	border-radius: 10px;
	animation-name: fadeOut;
	animation-fill-mode: forwards;
	animation-duration: $transition-timing + 0.05;
}
