@import 'modules';
@import 'vendors';
@import 'cors';

.overlay-box {
    position: absolute !important;
}

.active-editor {
    position: relative;
    animation-name: fadeIn;
    animation-fill-mode: forwards;
    animation-duration: $transition-timing + 0.05;

    &::before {
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 50;
        width: 100%;
        height: 100%;
        background-color: rgba($color-gray-5, 0.3);
    }
}
