@import '../../../modules';

.btn {
	composes: button from '../../common.scss';

	&_sm {
		composes: button_sm from '../../common.scss';
	}

	&_md {
		composes: button_md from '../../common.scss';
	}

	&_lg {
		composes: button_lg from '../../common.scss';
	}

	@each $available-color_key,
	$available-color_value in $bg-colors {
		&_#{str-replace($available-color_key, '-', '_')} {
			color: #fff;
			background-color: $available-color_value;
		}

		&_border {
			&_#{str-replace($available-color_key, '-', '_')} {
				color: $available-color_value;
				border-color: $available-color_value;
			}
		}
	}

	&_border {
		&_gray_lighter {
			&:hover {
				color: $color-gray-2 !important;
			}
		}

		&_white {
			&:hover {
				background-color: #fff;
				color: $color-primary;
			}
		}

		&_primary {
			&:hover {
				background-color: $color-primary;
				color: #fff;
			}
		}
	}

	&_white {
		color: $color-primary !important;

		&_primary {
			color: $color-primary !important;
			box-shadow: 0 5px 10px rgba($color-primary, 0.2);
			background-color: #fff;

			&:hover {
				background-color: $color-primary !important;
				color: #fff !important;
			}
		}

		&_secondary {
			color: $color-secondary !important;
			box-shadow: 0 5px 10px rgba($color-secondary, 0.3);
			background-color: #fff;

			&:hover {
				background-color: $color-secondary !important;
				// color: #fff !important;
			}
		}

		&_tertiary {
			color: $color-tertiary !important;
			box-shadow: 0 5px 10px rgba($color-tertiary, 0.3);
			background-color: #fff;

			&:hover {
				background-color: $color-tertiary !important;
				color: #fff !important;
			}
		}

		&_gray {
			color: $color-gray-5 !important;
			box-shadow: 0 5px 10px rgba($color-gray-5, 0.2);
			background-color: #fff;

			&:hover {
				background-color: $color-gray-5 !important;
				color: #fff !important;
			}
		}

		&_red {
			color: $color-red !important;
			box-shadow: 0 5px 10px rgba($color-red, 0.15);
			background-color: #fff;

			&:hover {
				background-color: $color-red !important;
				color: #fff !important;
			}
		}

		&_green {
			color: $color-green !important;
			box-shadow: 0 5px 10px rgba($color-green, 0.2);
			background-color: #fff;

			&:hover {
				background-color: $color-green !important;
				color: #fff !important;
			}
		}

		&_yellow {
			color: $color-yellow !important;
			box-shadow: 0 5px 10px rgba($color-yellow, 0.2);
			background-color: #fff;

			&:hover {
				background-color: $color-yellow !important;
				color: #fff !important;
			}
		}
	}

	&_tertiary,
	&_secondary,
	&_white_secondary:hover {
		color: $color-gray-5 !important;
	}

	&_primary {
		box-shadow: 0 5px 10px rgba($color-primary, 0.4);

		&:hover {
			background-color: $color-primary-light;
			color: #fff;
		}
	}

	&_secondary {
		box-shadow: 0 5px 10px rgba($color-secondary, 0.3);

		&:hover {
			background-color: $color-secondary-light;
			color: #fff;
		}
	}

	&_tertiary {
		box-shadow: 0 5px 10px rgba($color-tertiary, 0.3);

		&:hover {
			background-color: $color-tertiary-light;
			color: #fff;
		}
	}

	&_empty {
		background-color: transparent;
	}

	&_rounded {
		border-radius: 30px;
	}

	&[disabled] {
		opacity: 0.6;
		box-shadow: none;
		cursor: default;
		pointer-events: none;
		background-color: $color-gray-2 !important;
		color: $color-gray-3 !important;
	}
}