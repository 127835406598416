@import '../../../modules';
@import '../../../core/animation.scss';

.image {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
    overflow: hidden;
    padding: 10px;

    &Wrapper {
        width: 160px;
        height: 160px;
        border-radius: 50%;
        overflow: hidden;
        flex-shrink: 0;
        cursor: pointer;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
            :global(.edit-overlay) {
                animation-name: fadeIn;
                z-index: 100;
            }
        }
    }
}