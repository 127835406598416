@import '../../modules';

.card {
	display: flex;
	align-items: center;
	flex-direction: column;
	width: 140px;
	cursor: pointer;
	border-radius: 10px;
	flex-shrink: 0;
}

.content {
	border-radius: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 1.25rem;
	width: 100%;
	background-color: $color-gray-1;
	margin-bottom: 0.5rem;
	border: 3px solid transparent;
}

.title {
	white-space: normal;
	text-align: center;
}

.active {
	border-color: $color-primary-light;
}

.deActive {
	pointer-events: none;
	filter: grayscale(0.7);
	background-color: $color-gray-1 !important;
}

.image {
	width: 70px;
	margin-bottom: 10px;
}