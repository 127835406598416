@import '../../modules';

$picker-width: 250px;

.popover {
    position: absolute;
    top: 3rem;
    right: 25%;
    margin-right: calc($picker-width / -2);
    z-index: 5;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 5px 10px $box-shadow-color;
    padding: 0.5rem;
    width: $picker-width;

    @include respond-above(md) {
        right: 27%;
    }
}

.submit {
    margin-top: 270px;
}