@import '../../modules';
@import '../../core/animation';

.container {
    position: relative;
    border: 2px dashed $color-gray-3;
    padding: 5px;
    border-radius: 10px;
    // min-width: 250px;
    min-height: 35px;
    display: flex;
    align-items: center;

    &:not(.editing):hover {
        border-style: solid;
        border-color: $color-gray-3;
    }


    &:hover,
    &.editing {
        .toolbar {
            animation-name: fadeIn;
            z-index: 1;
        }
    }

    &.editing {
        .toolbar {
            right: 0;
        }
    }
}

.editing {
    background-color: #fff;

    :global(.active-editor) & {
        z-index: 122;
        border-radius: $input-border-radius;
        border-style: solid;
        border-color: $color-primary;
    }
}

.toolbar {
    display: flex;
    align-items: center;
    position: absolute;
    top: -40px;
    right: -5px;
    z-index: -1;
    padding-bottom: 1rem;
    animation-name: fadeOut;
    animation-fill-mode: forwards;
    animation-duration: 0s;
}

.input {
    width: 100%;
    padding: 0.5rem;
}

.editor {
    &_button {
        visibility: hidden;
        display: none;
    }

    &_link {
        composes: button from '../common.scss';
        composes: button_sm from '../common.scss';
        color: #fff !important;
        background-color: $color-primary;
        box-shadow: 0 5px 10px rgba($color-primary, 0.4);

        &:hover {
            background-color: $color-primary-light;
        }
    }

    &_viewcontainer {
        position: relative;

        +div {
            font-size: 13px;
            font-weight: 400;
            margin-top: 0.25rem;
            padding-left: 1rem;
            margin-bottom: 0.25rem;
            color: $color-red;
        }

        // input,
        // textarea {
        //     border-radius: $input-border-radius;
        // }

        textarea {
            min-height: 150px;
            border: none;
            resize: none;
        }
    }
}

.empty {
    border: 2px solid $color-gray-2;
    padding: 0.5rem 1rem;
    border-radius: 5px;
}