@import '../../../modules';
@import '../../../core/animation';

.card {
    background: #FFF;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    border: 1px solid $color-gray-1;
    padding: 1rem;
    height: 100%;
    gap: 0.5rem;
    position: relative;

    &:hover {
        cursor: pointer;

        :global(.edit-overlay) {
            animation-name: fadeIn;
        }
    }
}

.badge {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 1;
    color: #FFF;
    padding: 0.25rem 1rem;
    background-color: $color-primary;
    border-radius: 0.5rem 0px 0px 0px;

    // .deActive & {
    //     background-color: $color-gray-3;
    // }
}

.img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px 20px 0px 20px;
    background-color: $color-gray-1;

    &Box {
        position: relative;
        border-radius: 20px 20px 0px 20px;
        height: 230px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;

        &:before {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 100px;
            background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.88) 25%, rgba(0, 0, 0, 0.67) 55%, rgba(0, 0, 0, 0) 100%);

            .deActive & {
                border-radius: 20px 20px 0px 20px;
                height: 100%;
            }
        }

        .deActive & {
            filter: grayscale(1);
        }
    }
}

.description {
    -webkit-line-clamp: 5;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    line-height: 1.8em;
    margin-bottom: 0;
    flex-grow: 1;
    color: $color-gray-3;
}