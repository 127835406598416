@import '../../modules';

.box {
    border-radius: 10px;
    background-color: rgba($color-primary-light, 0.3);
    overflow: hidden;
    min-width: 150px;
    position: relative;

    @include respond-above(md) {
        min-width: 115px;
    }

    @include respond-above(lg) {
        min-width: 150px;
    }
}

.progress {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    background-color: rgba($color-primary-light, 0.4);
}

.content {
    position: relative;
    padding: 0.5rem 1.25rem;
}