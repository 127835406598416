@import '../../../modules';
@import '../../../core/animation';

.container {
	@include respond-above(md) {
		padding-right: 0.75rem !important;
		padding-left: 0.75rem !important;
	}
}

.image {
	object-fit: cover;
	height: 100%;
	width: 100%;
}

.contactImage {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 50%;
	margin: 0;
	background-color: $color-gray-1;

	&Wrapper {
		margin: 0 auto 1rem;
		width: 100%;
		max-width: 13rem;
		height: 13rem;
		border-radius: 50%;
		padding: 0.5rem;
		overflow: hidden;
		position: relative;
		cursor: pointer;

		@include respond-above(lg) {
			max-width: 16rem;
			height: 16rem;
		}
	}
}

.editable {
	width: 100%;
	height: 100%;
	position: relative;
}


.bodyBoxPremium{
	height: auto;
}

.boxTitle {
	margin: 0 50px;
	text-align: center;

	@include respond-above(lg) {
		margin: 0 243px;
	}

	@include respond-above(md) {
		margin: 0 243px;
	}
}

.boxPlan {
	background: #0000001a;
	border-width: 0px 0px 1px 1px;
	border-style: solid;
	border-color: #ffffff;
	color: #fff;
	padding: 30px;
	gap: 15px;
	border-radius: 30px;
	border: 0px 0px 1px 1px;
}

.boxPlanDrawer {
	color: #fff;
	gap: 15px;
	border-bottom: 1px solid #8d8da0;
	padding-bottom: 20px;


	@include respond-above(lg) {
		background: #0000001a;
		border-width: 0px 0px 1px 1px;
		border-style: solid;
		border-color: #ffffff;
		color: #fff;
		padding: 30px;
		gap: 15px;
		border-radius: 30px;
		border: 0px 0px 1px 1px;
	}

	@include respond-above(md) {
		background: #0000001a;
		border-width: 0px 0px 1px 1px;
		border-style: solid;
		border-color: #ffffff;
		color: #fff;
		padding: 30px;
		gap: 15px;
		border-radius: 30px;
		border: 0px 0px 1px 1px;
	}
}


.image {
    width: 100%;
    height: 100%;
    // border-radius: 50%;
    object-fit: cover;
    overflow: hidden;
    padding: 10px;

    &Wrapper {
        width: 250px;
        height: 250px;
        border-radius: 50%;
        overflow: hidden;
        flex-shrink: 0;
        cursor: pointer;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

		@include respond-above(lg) {
			width: 300px;
			height: 300px;
		}
	
		@include respond-above(md) {
			width: 250px;
			height: 250px;
		}

        &:hover {
            :global(.edit-overlay) {
                animation-name: fadeIn;
                z-index: 100;
            }
        }
    }
}
