// Global Animation
@-webkit-keyframes pulse {
	0% {
		opacity: 0.5;
	}

	100% {
		opacity: 1;
	}
}

@-moz-keyframes pulse {
	0% {
		opacity: 0.5;
	}

	100% {
		opacity: 1;
	}
}

@keyframes pulse {
	0% {
		opacity: 0.5;
	}

	100% {
		opacity: 1;
	}
}

@-webkit-keyframes opacityPulse {
	0% {
		opacity: 1;
	}

	50% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@keyframes opacityPulse {
	0% {
		opacity: 1;
	}

	50% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@-webkit-keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@-webkit-keyframes fadeOut {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

@keyframes fadeOut {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

@-webkit-keyframes fadeInTop {
	from {
		-webkit-transform: translate3d(0, -8px, 0);
		transform: translate3d(0, -8px, 0);
		opacity: 0;
	}

	to {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
		opacity: 1;
	}
}

@-webkit-keyframes fadeInTop {
	from {
		-webkit-transform: translate3d(0, -8px, 0);
		transform: translate3d(0, -8px, 0);
		opacity: 0;
	}

	to {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
		opacity: 1;
	}
}

@keyframes fadeInTop {
	from {
		-webkit-transform: translate3d(0, -8px, 0);
		transform: translate3d(0, -8px, 0);
		opacity: 0;
	}

	to {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
		opacity: 1;
	}
}

@-webkit-keyframes scaleIn {
	from {
		-webkit-transform: scale3d(0.35, 0.35, 0.35);
		transform: scale3d(0.35, 0.35, 0.35);
	}

	to {
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
	}
}

@keyframes scaleIn {
	from {
		-webkit-transform: scale3d(0.35, 0.35, 0.35);
		transform: scale3d(0.35, 0.35, 0.35);
	}

	to {
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
	}
}

@-webkit-keyframes slideInRight {
	from {
		-webkit-transform: translate3d(-15px, 0, 0);
	}

	to {
		-webkit-transform: translate3d(0, 0, 0);
	}
}

@keyframes slideInRight {
	from {
		-webkit-transform: translate3d(-15px, 0, 0);
	}

	to {
		-webkit-transform: translate3d(0, 0, 0);
	}
}

@-webkit-keyframes slideFromBottom {
	from {
		-webkit-transform: translate3d(0, 100%, 0);
		transform: translate3d(0, 100%, 0);
	}

	to {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

@keyframes slideFromBottom {
	from {
		-webkit-transform: translate3d(0, 100%, 0);
		transform: translate3d(0, 100%, 0);
	}

	to {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

@-webkit-keyframes slideFromLeft {
	from {
		-webkit-transform: translate3d(-100%, 0, 0);
		transform: translate3d(-100%, 0, 0);
	}

	to {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

@keyframes slideFromLeft {
	from {
		-webkit-transform: translate3d(-100%, 0, 0);
		transform: translate3d(-100%, 0, 0);
	}

	to {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

@-webkit-keyframes slideToLeft {
	from {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}

	to {
		-webkit-transform: translate3d(-100%, 0, 0);
		transform: translate3d(-100%, 0, 0);
	}
}

@keyframes slideToLeft {
	from {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}

	to {
		-webkit-transform: translate3d(-100%, 0, 0);
		transform: translate3d(-100%, 0, 0);
	}
}

@-webkit-keyframes slideToBottom {
	from {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}

	to {
		-webkit-transform: translate3d(0, 100%, 0);
		transform: translate3d(0, 100%, 0);
	}
}

@keyframes slideToBottom {
	from {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}

	to {
		-webkit-transform: translate3d(0, 100%, 0);
		transform: translate3d(0, 100%, 0);
	}
}

@-webkit-keyframes slideInLeft {
	from {
		-webkit-transform: translate3d(15px, 0, 0);
	}

	to {
		-webkit-transform: translate3d(0, 0, 0);
	}
}

@keyframes slideInLeft {
	from {
		-webkit-transform: translate3d(15px, 0, 0);
	}

	to {
		-webkit-transform: translate3d(0, 0, 0);
	}
}

@-webkit-keyframes slideOutLeft {
	from {
		-webkit-transform: translate3d(0, 0, 0);
	}

	to {
		-webkit-transform: translate3d(15px, 0, 0);
	}
}

@keyframes slideOutLeft {
	from {
		-webkit-transform: translate3d(0, 0, 0);
	}

	to {
		-webkit-transform: translate3d(15px, 0, 0);
	}
}

@-webkit-keyframes rotate {
	from {
		-webkit-transform: rotate(0deg);
	}

	to {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes rotate {
	from {
		-webkit-transform: rotate(0deg);
	}

	to {
		-webkit-transform: rotate(360deg);
	}
}

@-webkit-keyframes rippleEffect {
	0% {
		-webkit-transform: scale(1);
		transform: scale(1);
		opacity: 0.5;
	}

	100% {
		-webkit-transform: scale(1.6);
		transform: scale(1.6);
		opacity: 0;
	}
}

@keyframes rippleEffect {
	0% {
		-webkit-transform: scale(1);
		transform: scale(1);
		opacity: 0.5;
	}

	100% {
		-webkit-transform: scale(1.6);
		transform: scale(1.6);
		opacity: 0;
	}
}

@-webkit-keyframes slideLeft {
	from {
		-webkit-transform: translate3d(0, 0, 0);
	}

	to {
		-webkit-transform: translate3d(15px, 0, 0);
	}
}

@keyframes slideLeft {
	from {
		-webkit-transform: translate3d(0, 0, 0);
	}

	to {
		-webkit-transform: translate3d(15px, 0, 0);
	}
}

@-webkit-keyframes fadeInRight {
	from {
		-webkit-transform: translate3d(-5px, 0, 0);
		transform: translate3d(-5px, 0, 0);
		opacity: 0;
	}

	to {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
		opacity: 1;
	}
}

@keyframes fadeInRight {
	from {
		-webkit-transform: translate3d(-5px, 0, 0);
		transform: translate3d(-5px, 0, 0);
		opacity: 0;
	}

	to {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
		opacity: 1;
	}
}

@-webkit-keyframes fadeOutRight {
	0% {
		transform: translate3d(0, 0, 0);
		opacity: 1;
	}

	100% {
		transform: translate3d(5px, 0, 0);
		opacity: 0;
	}
}

@keyframes fadeOutRight {
	0% {
		transform: translate3d(0, 0, 0);
		opacity: 1;
	}

	100% {
		transform: translate3d(5px, 0, 0);
		opacity: 0;
	}
}

@-webkit-keyframes fadeOutLeft {
	0% {
		transform: translate3d(0, 0, 0);
		opacity: 1;
	}

	100% {
		transform: translate3d(-5px, 0, 0);
		opacity: 0;
	}
}

@keyframes fadeOutLeft {
	0% {
		transform: translate3d(0, 0, 0);
		opacity: 1;
	}

	100% {
		transform: translate3d(-5px, 0, 0);
		opacity: 0;
	}
}

@-webkit-keyframes fadeOutTop {
	to {
		-webkit-transform: translate3d(0, -8px, 0);
		transform: translate3d(0, -8px, 0);
		opacity: 0;
		visibility: hidden;
	}
}

@keyframes fadeOutTop {
	to {
		-webkit-transform: translate3d(0, -8px, 0);
		transform: translate3d(0, -8px, 0);
		opacity: 0;
		visibility: visible;
	}
}

@-webkit-keyframes fadeOutBottom {
	from {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
		visibility: visible;
		opacity: 1;
	}

	to {
		-webkit-transform: translate3d(0, 8px, 0);
		transform: translate3d(0, 8px, 0);
		visibility: hidden;
		opacity: 0;
	}
}

@keyframes fadeOutBottom {
	from {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
		visibility: visible;
		opacity: 1;
	}

	to {
		-webkit-transform: translate3d(0, 8px, 0);
		transform: translate3d(0, 8px, 0);
		visibility: hidden;
		opacity: 0;
	}
}

@-webkit-keyframes fadeInUp {
	from {
		-webkit-transform: translate3d(0, 8px, 0);
		transform: translate3d(0, 8px, 0);
		visibility: hidden;
		opacity: 0;
	}

	to {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
		visibility: visible;
		opacity: 1;
	}
}

@keyframes fadeInUp {
	from {
		-webkit-transform: translate3d(0, 8px, 0);
		transform: translate3d(0, 8px, 0);
		visibility: hidden;
		opacity: 0;
	}

	to {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
		visibility: visible;
		opacity: 1;
	}
}