@import '../../modules';

$player_icon-width: 5rem;
$player_icon-height: 5rem;

.container {
	aspect-ratio: 16/9;
	width: 100%;
	background: #fff;
	border-radius: 40px;
	position: relative;
	display: flex;
	align-items: center;

	&::after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background-color: $color-gray-4;
		border-radius: 40px;
		opacity: 0;
		cursor: pointer;
		transition: opacity $transition-timing;
	}

	&:hover .player_icon {
		transform: translate(-50%, -50%) scale(1.2);
	}

	&:hover {
		&::after {
			opacity: 0.6;
		}
	}
}

.image {
	width: 100%;
	aspect-ratio: 16/9;
	border-radius: 40px;
	overflow: hidden;
	background-repeat: no-repeat;
	background-position: center;
	background-size: 100%;
}

.player_icon {
	width: $player_icon-width;
	height: $player_icon-height;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 1;
	transform: translate(-50%, -50%);
	transition: transform $transition-timing;
}
