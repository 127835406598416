@import '../../modules';
@import '../../core//animation';

.table {
	@include respond-above(sm) {
		background: #fff;
		border-radius: $border-radius-15;
		box-shadow: 0 5px 10px rgba($color-gray-5, 0.05);
		padding: 0.5rem;
		position: relative;
	}

	:global(.card-box) & {
		box-shadow: none;
		padding: 0;
	}
}

.header {
	border-bottom: 1px solid $color-gray-1;
	min-height: 2.75rem;
}

.row {
	min-height: 2.75rem;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	transition: background-color $transition-timing, color $transition-timing;

	@include respond-below(sm) {
		background: #fff;
		padding: 0.5rem;
		border-radius: $border-radius-15;
		box-shadow: 0 5px 10px rgba($color-gray-5, 0.05);

		:global(.card-box) & {
			box-shadow: none;
			padding: 0;
		}

		:global(.table-row):not(:last-child) & {
			margin-bottom: 1.25rem;
		}
	}

	@include respond-above(sm) {
		margin-bottom: 0;

		&:not(:last-child) {
			border-bottom: 1px solid $color-gray-1;
		}
	}
}

.cell {
	line-height: 1;

	@include respond-below(sm) {
		display: flex;
		align-items: center;
		flex-grow: 1;
		line-height: 2rem;

		&:not(:last-child) {
			border-bottom: 1px solid $color-gray-1;
		}

		&:before {
			content: attr(data-th) '';
			margin-right: 1.25rem;
			font-weight: 500;
			flex-shrink: 0;
			color: $color-gray-3;
			font-size: 13px;
			width: 120px;
			line-height: 1.65em;
			padding-top: 0.25rem;
			padding-bottom: 0.25rem;
		}
	}
}

.loader {
	height: 2.5rem;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.actions {
	width: 2.25rem;
	height: 2.25rem;
	min-height: 2.25rem;
	padding: 0;
}

.loading {
	&Box {
		&:before {
			background-color: $color-gray-1;
			width: 5.5rem;
			height: 1.25rem;
			margin-top: .25rem;
			margin-bottom: 0.25rem;
			border-radius: 4px;
		}
	}

	&Row {
		height: 1.25rem;
		width: 100%;
		max-width: 7rem;

		@include respond-below(sm) {
			max-width: 9.5rem;
		}
	}
}