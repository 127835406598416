@import '../../modules';

.box {
	background-color: $color-gray-1;
	border-radius: 10px;
	padding: 20px;
}

.inputbox {
	background-color: transparent;
	height: 100%;
	width: 100%;
	flex: 1 1;
	padding: 0.5rem;

	&:disabled {
		cursor: not-allowed;
	}

	&Wrapper {
		height: 3rem !important;
		padding: 0 0.25rem !important;
		background-color: #fff !important;
	}
}

.error {
	border: 1px solid red;
}

.summary {
	@include respond-above(xs) {
		margin-right: 0;
		margin-left: auto;
		max-width: 100%;
	}
}

.row {
	display: flex;
	align-items: center;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;

	&:not(:last-of-type) {
		border-bottom: 1px solid $color-gray-2;
	}

	&:nth-last-child(2) {
		border-color: $color-gray-3;
	}
}

.label {
	min-width: 180px;
	font-size: 16px;
	margin-right: 0.5rem;
	display: flex;
	align-items: center;
	gap: 0.25rem;
}

.value {
	margin-left: auto;
	font-weight: 500;
	font-size: 16px;
}
