/* # Site base color setting
------------------------------------------- */
$color-yellow: #ff9800;
$color-red: #f44336;
$color-green: #96bd68;
$color-blue: #4a7dff;
$color-yellow-job: #c69d17;

// Primary colors
$color-primary: #96bd68;
$color-primary-light: #aed67e;
$color-primary-dark: #648e33;

// Secondary colors
$color-secondary: #ff8c00;
$color-secondary-light: #fcc597;
$color-secondary-dark: #f08400;

// Tertiary colors
$color-tertiary: #74dbf7;
$color-tertiary-light: #aae8f2;
$color-tertiary-dark: #5ebad3;

// Gray colors
$color-gray-1: #f2f2f4;
$color-gray-2: #d5d5db;
$color-gray-3: #8d8da0;
$color-gray-4: #5d5c70;
$color-gray-5: #403e50;
$color-gray-6: #242429;
$color-gray-7: #eff2f5;

/* # Project setting
------------------------------------------- */
$body-color: $color-gray-4;
$border_color: $color-gray-2;
$box-shadow-color: rgba($color-gray-5, 0.1);
$text-colors: (
	'yellow': $color-yellow,
	'green': $color-green,
	'red': $color-red,
	'primary': $color-primary,
	'primary-light': $color-primary-light,
	'primary-dark': $color-primary-dark,
	'secondary': $color-secondary,
	'secondary-light': $color-secondary-light,
	'secondary-dark': $color-secondary-dark,
	'tertiary': $color-tertiary,
	'tertiary-light': $color-tertiary-light,
	'tertiary-dark': $color-tertiary-dark,
	'gray-1': $color-gray-1,
	'gray-2': $color-gray-2,
	'gray-3': $color-gray-3,
	'gray-4': $color-gray-4,
	'gray-5': $color-gray-5,
	'gray-6': $color-gray-6,
	'gray-7': $color-gray-7,
	'warning': $color-yellow,
	'success': $color-green,
	'error': $color-red,
	'muted': $color-gray-3
);

$shared-colors: (
	'white': #fff,
	'yellow': $color-yellow,
	'green': $color-green,
	'red': $color-red,
	'warning': $color-yellow,
	'success': $color-green,
	'error': $color-red,
	'muted': $color-gray-3
);

$bg-colors: (
	'white': #fff,
	'yellow': $color-yellow,
	'green': $color-green,
	'red': $color-red,
	'gray-1': $color-gray-1,
	'gray-2': $color-gray-2,
	'gray-3': $color-gray-3,
	'gray-4': $color-gray-4,
	'gray-5': $color-gray-5,
	'gray-6': $color-gray-6,
	'gray-7': $color-gray-7,
	'primary': $color-primary,
	'primary-light': $color-primary-light,
	'primary-dark': $color-primary-dark,
	'secondary': $color-secondary,
	'secondary-light': $color-secondary-light,
	'secondary-dark': $color-secondary-dark,
	'tertiary': $color-tertiary,
	'tertiary-light': $color-tertiary-light,
	'tertiary-dark': $color-tertiary-dark,
	'warning': $color-yellow,
	'success': $color-green,
	'error': $color-red,
	'muted': $color-gray-2
);
