@import '../../../modules';

.card {
	border-radius: 30px !important;
	border: 1px solid $color-gray-1;
	background-color: #fff !important;
	padding: 1rem !important;

	&:not(:last-child) {
		margin-bottom: 1.5rem;
	}
}

.content {
	border-bottom: 1px solid $border-color;
	margin-bottom: 10px;
	padding-bottom: 10px;

	@include respond-below(sm) {
		margin-top: 10px;
	}
}

.image {
	object-fit: cover;
	border-radius: 20px 20px 0px 20px;
	width: 100%;
	height: 100%;

	&Box {
		overflow: hidden;
		border-radius: 20px;

		@include respond-above(sm) {
			border-radius: 20px 20px 0px 20px;
		}

		&Wrapper {
			aspect-ratio: 16/9;
		}
	}
}

.loading {
	&Col {
		height: 20px;
		width: 100%;

		&Small {
			max-width: 100px;
		}

		&Big {
			max-width: 220px;
		}
	}
}