@import '../../modules';

.wizard {
	border-radius: 20px;
	background-color: #fff;
	// @include respond-above(sm) {
	// }
}

.box {
	background-color: $color-primary;
	border-radius: 20px 20px 0 0;
	padding: 1rem 20px;
	flex-shrink: 0;
	display: flex;
	justify-content: center;

	@include respond-below(sm) {
		min-height: 90px;
	}

	@include respond-above(sm) {
		border-radius: 20px 0 0 20px;
		padding: 2rem;
		max-width: 270px;
	}

	@include respond-above(lg) {
		padding: 2rem 2.25rem;
		max-width: 300px;
	}
}

.boxVideo {
	background-color: $color-gray-1;
	border-radius: 8px;
	padding: 8px 50px 8px 8px;
	gap: 16px;
	display: flex;
	align-items: center;
}

.video {
	width: 232px;
	height: 100%;

	img {
		border-radius: 8px;
	}
}

.step {
	margin-top: 1.25rem;
	flex: 1;

	@include respond-below(sm) {
		margin-top: 0;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}

.form {
	flex-grow: 1;
	padding: 2rem 20px;
	position: relative;

	@include respond-above(sm) {
		padding: 2rem;
		width: 90%;
	}

	@include respond-above(md) {
		padding: 4rem 20px;
		width: 60%;
	}

	@include respond-above(lg) {
		padding: 2rem 2.25rem;
		width: 60%;
	}
}

.edit {
	position: absolute;
	right: 0;
	top: 0;
	width: 115px;
	padding: 0.5rem;
	z-index: 1;
	gap: 5px;
}

.logo {
	width: 100%;
	height: 100%;
	margin-left: 0;
	margin-right: 0;
	object-fit: contain;
	border-radius: 5px;

	&Wrapper {
		overflow: hidden;
		flex-shrink: 0;
		width: 45px;
		height: 45px;
		border-radius: 5px;
	}
}

.image {
	width: 100%;
	// height: 165px;
	border-radius: 10px;
	object-fit: cover;
	background-color: $color-gray-1;
	aspect-ratio: 16/9;

	@include respond-above(lg) {
		margin-top: 0;
	}

	@include respond-above(xl) {
		max-height: 195px;
	}
}

.carousel {
	// padding: 0.5rem 1.75rem 2rem;
}

.bullets {
	ul {
		list-style: none;
	}
	ul > li {
		line-height: 1.65em;
		padding-left: 1.7rem;
		position: relative;
		list-style: none;

		&:not(:last-child) {
			margin-bottom: 0.25rem;
		}

		&:before {
			content: '\f14a';
			font-size: 20px;
			left: 0;
			font-family: 'Font Awesome 6 Duotone';
			letter-spacing: normal;
			top: 2px;
			background: #ffb371b7;
			border-radius: 3px;
			width: 18px;
			height: 18px;
			justify-content: center;
			align-items: center;
			display: flex;
			color: #ff8c00;
			position: absolute;
		}
	}
}

.boxSwiper {
	width: 100%;
}

.mySwiper {
	padding: 3rem !important;
}

.swiperSlide {
	border: 1px solid #e7e7ec !important;
	border-radius: 16px !important;
	padding: 16px 16px 24px 16px !important;
	display: flex !important;
	flex-direction: column !important;
	height: auto !important;
	margin: 0px 20px 20px 0px;
	justify-content: space-between !important;
}

.width100 {
	width: 80% !important;
	margin: auto;
}

.selected {
	padding: 16px 16px 24px 16px;
	border-radius: 16px;
	background-color: rgba($color-primary-light, 0.2) !important;
	outline: 1px solid $color-primary;
	border: 1px solid #e7e7ec;
}

.cardPayment {
	background: #fff;
	// box-shadow: 0px 4px 10px 0px #3e552333;
	padding: 16px;
	border-radius: 10px;
	border: 1px solid #e7e7ec;
	cursor: pointer;
}
.addCardPayment {
	background: linear-gradient(180deg, #efeff2 0%, #f8f8f9 100%);
	padding: 16px;
	border-radius: 10px;
	cursor: pointer;
}

@keyframes skeleton-loading {
	to {
		background-position: left;
	}
}

.cardPaymentSkeleton {
	background: linear-gradient(
			90deg,
			rgba(238, 236, 236, 0.667) 40%,
			#ffffffaa,
			rgba(238, 236, 236, 0.667) 60%
		)
		right / 300% 100%;
	animation: skeleton-loading 2.5s linear infinite;
	height: 85px;
	border-radius: 10px;
}

.labelSkeleton {
	background: linear-gradient(
			90deg,
			rgba(238, 236, 236, 0.667) 40%,
			#ffffffaa,
			rgba(238, 236, 236, 0.667) 60%
		)
		right / 300% 100%;
	animation: skeleton-loading 2.5s linear infinite;
	height: 20px;
	width: 100px;
	border-radius: 4px;
}

.boxPaymentResult {
	padding: 16px;
	border-radius: 8px;
	display: flex;
	align-items: center;
	margin-bottom: 24px;
}

.disabledCardPayment {
	background: linear-gradient(
			90deg,
			rgba(238, 236, 236, 0.667) 40%,
			#ffffffaa,
			rgba(238, 236, 236, 0.667) 60%
		)
		right / 300% 100%;
	height: 85px;
	padding: 16px;
	border-radius: 10px;
}

.roundedMd {
	border-radius: 8px;
}

.cardPlan {
	p {
		margin: 0 !important;
		padding: 0 !important;
		line-height: normal !important;
	}
	ul,
	ol {
		list-style: revert !important;
		list-style-image: inherit !important;
		padding: revert !important;
		margin: auto !important;
		li {
			list-style: unset !important;
			list-style-image: 0 !important;
		}
	}
}

.cardCommunityPartner {
	padding: 1rem 2rem;
	border-radius: 15px;
	position: relative;
	display: flex;
	width: 100%;
	margin: 10px 10px;
	// align-items: center;
	min-height: 310px;
	background: linear-gradient(90deg, #ddda 40%, #efefefaa, #ddda 60%) right /
		300% 100%;
	animation: skeleton-loading 2.5s linear infinite;

	&:not(:last-of-type) {
		border-bottom: 1px solid $color-gray-1;
		padding-bottom: 10px;
		margin-bottom: 10px;
	}
}

.cardPlanSkeleton {
	padding: 1rem 2rem;
	border-radius: 15px;
	position: relative;
	display: flex;
	width: 100%;
	// align-items: center;
	min-height: 510px;
	background: linear-gradient(90deg, #ddda 40%, #efefefaa, #ddda 60%) right /
		300% 100%;
	animation: skeleton-loading 2.5s linear infinite;

	&:not(:last-of-type) {
		border-bottom: 1px solid $color-gray-1;
		padding-bottom: 10px;
		margin-bottom: 10px;
	}
}

.cardPlanImge {
	padding: 1rem 2rem;
	border-radius: 8px;
	position: relative;
	display: flex;
	width: 100%;
	// align-items: center;
	min-height: 90px;
	background: linear-gradient(90deg, #ddda 40%, #efefefaa, #ddda 60%) right /
		300% 100%;
	animation: skeleton-loading 2.5s linear infinite;

	&:not(:last-of-type) {
		border-bottom: 1px solid $color-gray-1;
		padding-bottom: 10px;
		margin-bottom: 10px;
	}
}

.logoCommunityPartner {
	width: 210px;
	height: 150px;
	background: linear-gradient(90deg, #ddda 40%, #efefefaa, #ddda 60%) right /
		300% 100%;
}
.labelHeder {
	width: 210px;
	height: 15px;
	background: linear-gradient(90deg, #ddda 40%, #efefefaa, #ddda 60%) right /
		300% 100%;
}
.labelButton {
	width: 100%;
	height: 30px;
	background: linear-gradient(90deg, #ddda 40%, #efefefaa, #ddda 60%) right /
		300% 100%;
}
.labelPrice {
	width: 120px;
	height: 15px;
	background: linear-gradient(90deg, #ddda 40%, #efefefaa, #ddda 60%) right /
		300% 100%;
}
.labelText {
	width: 210px;
	height: 10px;
	background: linear-gradient(90deg, #ddda 40%, #efefefaa, #ddda 60%) right /
		300% 100%;
}
.badgeSkeleton {
	width: 100px;
	height: 25px;
	background: linear-gradient(90deg, #ddda 40%, #efefefaa, #ddda 60%) right /
		300% 100%;
}
.labelDescription {
	width: 100%;
	height: 10px;
	background: linear-gradient(90deg, #ddda 40%, #efefefaa, #ddda 60%) right /
		300% 100%;
}
.imgeSkeleton {
	width: 100%;
	height: 200px;
	background: linear-gradient(90deg, #ddda 40%, #efefefaa, #ddda 60%) right /
		300% 100%;
}

.boxCurrentPlan {
	margin-top: 24px;
	background: linear-gradient(180deg, #efeff2 0%, #f8f8f9 100%);
	padding: 16px;
	border-radius: 16px;
}

$scrollbar-track-color: #f1f1f1;
$scrollbar-thumb-color: #888;
$scrollbar-thumb-hover-color: #555;

::-webkit-scrollbar {
	width: 6px;
	height: 12px;
}

::-webkit-scrollbar-track {
	background: $scrollbar-track-color;
	border-radius: 10px;
}

::-webkit-scrollbar-thumb {
	background: $scrollbar-thumb-color;
	border-radius: 10px;

	&:hover {
		background: $scrollbar-thumb-hover-color;
	}
}

.row {
	display: flex;
	align-items: center;
	min-height: 1rem;
	margin-bottom: 0.5rem;
	flex-wrap: wrap;

	&Label {
		padding-right: 0.5rem;
		min-width: 160px;
	}

	@include respond-below(xs) {
		justify-content: space-between;
	}

	@include respond-below(sm) {
		padding-bottom: 0.5rem;
		border-bottom: 1px solid $color-gray-1;

		&:last-child {
			border-bottom: 0;
			margin-bottom: 1.25rem;
		}
	}
}

.boxPlanUpgradeStep2 {
	background: linear-gradient(180deg, #efeff2 0%, #f8f8f9 100%);
	padding: 16px;
	gap: 10px;
	border-radius: 16px;
	width: 48%;
}
