@import '../../modules';

.item {
    display: flex;
    align-items: center;
    gap: 1rem;
    height: 3.5rem;
    flex: 1;
    cursor: pointer;
    color: $color-gray-1;
    transition: all 0.2s;

    &:hover {
        color: #fff;
    }

    &Box {
		&.active {
			background-color: rgba(#000, 0.05);
			margin-right: -25px;
			margin-left: -25px;
			padding-right: 25px;
			padding-left: 25px;
		}
	}
}

.isActive {
    color: #fff;
}

.sub {
    &List {
        margin-bottom: 0.5rem;

        >li:not(:last-child) {
            .subitem {
                margin-bottom: 0.5rem;
            }
        }
    }

    &item {
        position: relative;
        display: flex;
        align-items: center;
        padding: 0.5rem 0.5rem 0.5rem 2.5rem;
        border-radius: 10px;

        &:hover,
        &_active {
            background-color: rgba(#fff, 0.1);
        }
    }

    &_dot {
        display: flex;
        position: absolute;
        left: 0.5rem;
        width: 0.5rem;
        height: 0.5rem !important;
    }
}

.separator {
    &:not(:last-of-type) {
        border-bottom: 1px solid rgba(#fff, 0.1);
        margin-bottom: 0.5rem;
    }
}