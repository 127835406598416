@import '../../modules';

.header {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	padding-top: 1rem;
	padding-bottom: 0.5rem;
	z-index: 123;
	transition: all $transition-timing;
	
	:global(.scroll) & {
		padding-top: 0.5rem;
		box-shadow: 0 2px 10px rgba(#000, 0.1);
		background-color: #96bd68;
	}
}

.container {
	height: 100%;

	@include respond-above(xl) {
		max-width: 1600px !important;
	}
}

.navbar {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 100%;
	gap: 0.5rem;
}

.actionButton {
	height: 40px;
	&.colorPicker {
		border-width: 2px;
	}
}

.theme_color {
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	width: 2rem;
	height: 2rem;
	border-radius: 5px;
	border: 2px solid $color-gray-1;
}

.menuBox {
	background-color: #96bd68;
	color: #fff;
	font-size: 15px;
	cursor: pointer;
	gap: 40px;
	border-radius: 50px;
	padding: 1rem 2rem;
}
