@import '../../modules';

.container {
	flex: 1;
	height: 100%;
	position: relative;
}

.scrollbar {
	max-height: 230px;
}

.suggestionsContainer {
	position: absolute;
	background-color: #fff;
	width: 100%;
	right: 0;
	left: 0;
	top: calc(100% + 0.5rem);
	z-index: 50;
	border-radius: 10px;
	box-shadow: 0 3px 6px $box-shadow-color;
	overflow: hidden;

	ul[role='listbox'] {
		padding: 0.5rem 0;
		margin: 0;
		list-style: none;
	}
}

.suggestionItem {
	padding: 0.5rem 0.75rem;
	cursor: pointer;
	font-size: 13px;
	min-height: 2.75rem;
	color: $body-color;

	&:hover {
		background-color: $color-gray-1;
	}
}

.loading {
	position: absolute;
	right: 0.5rem;
	top: 50%;
	margin-top: -10px;
	z-index: 10;
	// color: #fff;
}