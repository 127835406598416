* {
	background: none;
	border-color: inherit;
	border-style: none;
	border-width: 0;
	font-size: 100%;
	margin: 0;
	padding: 0;
	vertical-align: baseline;
	outline: none;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	text-rendering: optimizeLegibility;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	box-sizing: border-box;
}

*:before,
*:after {
	box-sizing: border-box;
}

*:focus {
	outline: 0;
}

body {
	color: $body_color;
	letter-spacing: 0.01em;

	font: {
		family: $font-family;
		size: 14px;
		weight: 400;
	}

	&.modal-open {
		padding-right: 0 !important;
	}
}

html {
	height: 100%;
	-ms-overflow-style: scrollbar;
	/* Allow percentage-based heights in the application */
	block-size: 100%;

	/* Smooth scrolling for users that don't prefer reduced motion */
	@media (prefers-reduced-motion: no-preference) {
		&:focus-within {
			scroll-behavior: smooth;
		}
	}
}

.root {
	min-height: 100vh;
}

a {
	color: inherit;
	text-decoration: none;
	outline: 0;
	text-shadow: 1px 1px 1px rgba(#000, 0.004);
	transition: all $transition-timing;
	@include font-smoothing;

	&:hover {
		color: inherit;
	}

	&:hover,
	&:active,
	&:visited {
		text-decoration: none;
	}

	&:focus {
		outline: none;
	}

	&[href] {
		cursor: pointer;
	}
}

ul,
ol {
	list-style: none;
	list-style-image: none;
	padding: 0;
	margin: 0;

	li {
		list-style: none;
		list-style-image: none;
	}
}

img {
	margin: 0 auto;
	max-width: 100%;
	height: auto;
	display: block;
	border: 0;
	outline: 0;
	font-size: 13px;
	-ms-interpolation-mode: bicubic;
	image-rendering: optimizeSpeed;
	line-height: 1.5;
	text-align: center;
}

svg {
	&:not(:root) {
		overflow: hidden;
	}
}

small {
	font-size: 75%;
}

sub,
sup {
	line-height: 0;
	position: relative;
}

sup {
	top: -0.5em;
}

sub {
	bottom: -0.25em;
}

pre {
	white-space: pre-wrap;
	/* Since CSS 2.1 */
	white-space: -moz-pre-wrap;
	/* Mozilla, since 1999 */
	white-space: -pre-wrap;
	/* Opera 4-6 */
	white-space: -o-pre-wrap;
	/* Opera 7 */
	word-wrap: break-word;
	/* Internet Explorer 5.5+ */
}