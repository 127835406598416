@import '../../../modules';

.card {
	border-radius: 15px;
	background-color: rgba($color-primary, 0.5);
	width: 100%;
	height: 100%;
	max-width: 300px;
	margin-right: auto;
	margin-left: auto;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	&Lg {
		min-height: 20rem;
		@include respond-above(xl) {
			min-height: 28rem;
		}
	}
	&Md {
		min-height: 15rem;
		@include respond-above(xl) {
			min-height: 20rem;
		}
	}
	&Sm {
		min-height: 10rem;
		@include respond-above(lg) {
			min-height: 12rem;
		}
	}
}

.edit {
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	background-color: $color-primary;
	color: #fff;
	width: 45px;
	height: 45px;
}
