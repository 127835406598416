@import '../../../modules';

.box {
	width: 85%;
	text-align: center;
	margin: -50px auto 0;
	position: relative;
	@include respond-above(lg) {
		margin-bottom: 0;
	}
}

.content {
	padding: 25px 15px;
    min-height: 50px;
    color: #fff;
    border-radius: 15px;
    text-align: center;
}

.title {
	font-size: 18px;
	margin-bottom: 10px;
	font-weight: 700;
}
