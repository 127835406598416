@import '../../../modules';

.boxSection {
	background-color: #f4f4f4;
	padding: 32px;
	border-radius: 16px;
}

.content {
	background-color: #fff;
	padding: 20px;
	border-radius: 7px;
	box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
}

.listGroupItem {
	.cardTitle {
		cursor: pointer;
		position: relative;
		z-index: 121;
	}
	.cardCollapse {
		overflow: hidden;
		transition: height 0.3s ease-out;
	}
	// .card-body {
	//   border: none !important; // why do we need this? (doesn't seem to change anything)
	// }
	h2 {
		margin-top: 5px !important;
	}
}

.headerCollapsible {
	width: 95%;

	@include respond-above(lg) {
		width: 96.5%;
    }
}
.buttonCollapsible {
	background: #fff;
	border-radius: 50%;
	padding: 10px 12px;
	position: absolute;
	top: 0;
	right: -1rem;
}
