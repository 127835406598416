@import '../../../modules';

$image-size: 2.5rem;

.menu {
    width: 14.5rem;
    border: none !important;
    margin-top: 0.5rem !important;
    border-radius: 10px !important;
    box-shadow: 0 5px 10px $box-shadow-color !important;
}

.item {
    height: 40px;

    &:hover {
        background-color: $color-gray-1 !important;
        color: $color-primary;
    }
}

.arrow {
    :global(.show) & {
        transform: rotate(-180deg);
    }
}

.avatar {
    border-radius: 50px;
    margin: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;

    &Box {
        width: $image-size;
        height: $image-size;
        border-radius: 50px;
        overflow: hidden;
        box-shadow: 0 5px 10px $box-shadow-color;
    }
}

.loading {
    &Sm {
        height: 20px;
        width: 100px;
    }

    &Md {
        height: 20px;
        width: 160px;
    }
}