@import '../../../modules';

.loading {
	background-color: $color-gray-2;
	height: 1.25rem;

	&Sm {
		height: 1rem;
	}

	&Icon {
		width: 2rem;
		height: 2rem;
	}
}