@import '../../modules';

.card {
	display: flex;
	gap: 0.75rem;
	border-radius: 10px;
	text-align: left;
	background-color: #fff;
	cursor: pointer;
	min-height: 60px;
	padding: 1rem;
	width: 100%;
	box-shadow: 0 5px 15px $box-shadow-color;
	outline: 1px solid $color-gray-2;
}

.selected {
	outline: 1px solid $color-primary;
	background-color: rgba($color-primary, 0.1);
	box-shadow: 0 5px 15px rgba($color-primary, 0.1);
}

.disabled {
	opacity: 0.6;
	box-shadow: none;
	cursor: default;
	pointer-events: none;
	background-color: #d5d5db !important;
	color: #8d8da0 !important;
}

.radio {
	margin-top: 0.25rem;
	border-radius: 50px;
	border: 1px solid $color-gray-2;
	width: 16px;
	height: 16px;
	flex-shrink: 0;
	display: flex;
	align-items: center;
	justify-content: center;

	.selected & {
		border-color: $color-primary;
	}
}

.dot {
	.selected & {
		width: 8px;
		height: 8px;
		border-radius: 50px;
		background-color: $color-primary;
	}
}
