@import '../../modules';

.box {
    width: 100%;
    height: 100%;
    min-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 3rem;
    border-radius: $border-radius-10;
    position: relative;

    &Loading {
        border-radius: $border-radius-10;

        &:before {
            content: '';
            position: absolute;
            border-radius: $border-radius-10;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            z-index: 1;
            background-color: rgba($color-gray-5, 0.9);
        }
    }
}

.image {
    min-height: 200px;
    border-radius: $border-radius-10;
    object-fit: contain;
    flex-grow: 1;
    height: 100%;
    // width: 100%;
    
    &Ratio {
        height: auto;
        aspect-ratio: 16/9;
        background-color: $color-gray-1;
    }
}

.loading {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
}