@import '../../modules';

.pre {
    padding: 10px;
    border-radius: 5px;
    background-color: $color-gray-5;
    color: $color-gray-1;
    font-size: 14px;
    width: 100%;
}

.filter_section {
    &:not(:last-child) {
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid $color-gray-1;
    }
}