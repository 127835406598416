@import '../../../modules';

.grid {
	border-radius: 40px;
	border-bottom: 3px solid;
	padding: 2rem 1.25rem;
	display: flex;
	flex-direction: column;
	gap: 1.5rem;

	@include respond-above(md) {
		padding: 2.5rem 3rem;
	}
}