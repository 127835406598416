@import '../../../modules';

.carousel {
	border-radius: 30px;
	height: 100%;
}

.slide {
	height: auto;
	border-radius: 30px;
	position: relative;
	padding-bottom: 1.5rem;

	@include respond-above(lg) {
		padding-left: 8%;
		padding-right: 8%;
	}

	&Image {
		border-radius: 30px;
		object-fit: cover;
		width: 100%;
		aspect-ratio: 16/9;
		height: auto;
	}
}

.swiperNav {
	width: 170px;
	height: 3rem;
	left: 50% !important;
	bottom: -1.5rem;
	top: auto !important;
	margin-left: calc(-170px / 2);
	z-index: 1;
	border-radius: 50px !important;
	overflow: hidden;
	padding: 0.5rem;
	gap: 1rem;
	display: flex;
	align-items: center;
	justify-content: flex-end;

	button {
		width: unset !important;
		color: #fff !important;
		position: static !important;
		margin-left: 0.25rem;

		svg {
			width: 30px;
			font-size: 20px;
		}
	}
}

.pagination {
	background-color: #fff;
	border-radius: 50px !important;
	width: 65px !important;
	height: 30px;
	left: calc(50% - 40px);
	transform: translateX(-50%);
	bottom: 9px;
	line-height: normal;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0.5rem 0.75rem;
	font-size: 14px;
	color: $color-gray-3;
}

.add_slide {
	background-color: $color-gray-2;
	color: $color-gray-4;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	border-radius: 30px;
	width: 100%;
	height: 100%;
	min-height: 300px;

	@include respond-above(sm) {
		height: 612px;
	}
}