@import '../../modules';

.card {
    display: flex;
    flex-direction: column;
    height: 100%;
}

$box-color: (
    'pending': $color-yellow,
    'approved': $color-green,
    'rejected': $color-red,
    'finished': $color-gray-4
);

@each $color_key,
$color_value in $box-color {
    .#{$color_key} {
        background-color: rgba($color_value, 0.1) !important;
        box-shadow: 0 10px 20px rgba($color_value, 0.1) !important;
    }
}

.logo {
    object-fit: contain;
    border-radius: 50px;
    width: 100%;
    height: 100%;
    margin-left: 0;
    background-color: #fff;
    padding: 2px;
    overflow: hidden;

    &Box {
        flex-shrink: 0;
        margin-right: 10px;
        width: 35px;
        height: 35px;
        border-radius: 50px;
        overflow: hidden;
        box-shadow: 0 3px 6px 0 $box-shadow-color;
    }
}

.time {
    &Row {
        display: flex;
        align-items: center;
        font-weight: 500;
        gap: 5px;
        line-height: 1.6;
        flex-wrap: wrap;

        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }
}