@import '../../modules';

.header {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	padding-top: 1rem;
	padding-bottom: 1rem;
	z-index: 123;
	transition: all $transition-timing;

	:global(.scroll) & {
		box-shadow: 0 2px 10px rgba(#000, 0.1);
		background-color: #fff;
	}
}

.container {
	height: 100%;

	@include respond-above(xl) {
		max-width: 1600px !important;
	}
}

.navbar {
	height: 100%;
	flex-wrap: wrap;
	gap: 0.5rem;
}

.actionButton {
	height: 40px;
	&.colorPicker {
		border-width: 2px;
	}
}

.theme_color {
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	width: 2rem;
	height: 2rem;
	border-radius: 5px;
	border: 2px solid $color-gray-1;
}
