/* # General settings:
------------------------------------------- */
$context_font-size        : 16;
$image-path               : '/assets/img' !default;
$font-family              : 'Ubuntu', Segoe UI, sans-serif;
$transition-timing        : 0.3s;
$transition-function      : cubic-bezier(0.2, 1, 0.8, 1);

/* # Site settings:
------------------------------------------- */
$border-radius-5          : 5px;
$border-radius-10         : 10px;
$border-radius-15         : 15px;
$border-radius-20         : 20px;
$input-border-radius      : 10px;
$sidebar-width            : 270px;
$header-height-desktop    : 90px;
$header-height-mobile     : 70px;
$section_mb               : 120px;
$modal-padding            : 20px;

// Profile page variables
$base_z-Index             : 10;
$overlay-z-index          : 120;
$overlay-content-z-index  : 3;
$header_height_mobile     : 70px;
$header_height_desktop    : 90px;
$header_height_scroll     : 70px;

// Responsive variables
$container-xxs            : 420px;
$container-xs             : 576px;
$container-sm             : 768px;
$container-md             : 992px;
$container-lg             : 1200px;
$container-xl             : 1440px;

$breakpoints: (
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px
);