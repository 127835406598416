@import '../../../modules';

.form {
    max-width: 350px;
}

.input {
    background-color: transparent !important;
    height: 100%;
    flex: 1;
    padding-right: 15px;
    padding-left: 15px;
}

.submit {
    width: 40px;
}