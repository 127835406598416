@import '../modules';

.card {
	&-box {
		background: #fff;
		border-radius: $border-radius-15;
		box-shadow: 0 5px 10px $box-shadow-color;
		padding: 1rem;
		position: relative;

		&:not(:last-of-type) {
			margin-bottom: 1.5rem;
		}

		@include respond-above(xs) {
			&--md {
				padding: 1.5rem;
			}
		}

		&--has-tab {
			padding-bottom: 0;
		}
	}
}