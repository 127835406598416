@import '../../modules';

.button {
    composes: button from '../common.scss';
    composes: button_sm from '../common.scss';
    color: $color-primary !important;
    box-shadow: 0 5px 10px rgba($color-primary, 0.2);
    background-color: #fff;

    &:hover {
        background-color: $color-primary !important;
        color: #fff !important;
    }
}