@import '../modules';

$sides: (
	left: 'left',
	right: 'right',
	top: 'top',
	bottom: 'bottom'
);

@each $available-color_key, $available-color_value in $bg-colors {
	.border-#{$available-color_key} {
		border-color: $available-color_value !important;
	}
}

@each $side_key, $side_value in $sides {
	@each $grid_key, $grid_value in $breakpoints {
		@include respond-above($grid_key) {
			.border-#{$side_key} {
				border-#{$side_key}: 1px solid $color-gray-2;
			}
			.border-#{$grid_key}-#{$side_key} {
				border-#{$side_key}: 1px solid $color-gray-2;
			}
			.border-#{$grid_key}-#{$side_key}-none {
				border-#{$side_key}: none;
			}
		}
	}
}
