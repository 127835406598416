@import '../../modules';

.tab {
	display: flex;
	overflow-x: auto;
	white-space: nowrap;
}

.action {
	composes: button from '../common.scss';
	composes: button_md from '../common.scss';
	color: #fff;
	background-color: $color-primary;
	box-shadow: 0 5px 10px rgba($color-primary, 0.3);

	&:hover {
		color: #fff;
		background-color: $color-primary-light;
	}
}

.openLink {
	composes: button from '../common.scss';
	composes: button_sm from '../common.scss';
	color: $color-primary;
	background-color: #fff;
	box-shadow: 0 5px 10px rgba(#ababab, 0.3);

	&:hover {
		color: $color-primary;
		background-color: #fff;
	}
}

.avatar {
	width: 100%;
	height: 100%;
	object-fit: contain;
	margin-left: 0;
	border-radius: 50px;
	background-color: #fff;

	&Box {
		width: 3rem;
		height: 3rem;
		flex-shrink: 0;
		border-radius: 50px;
		display: block;
		overflow: hidden;
		box-shadow: 0 5px 10px $box-shadow-color;
	}
}

.list {
	&Title {
		&:hover {
			color: $color-primary;
		}
	}
}

.formAction {
	position: absolute;
	right: 20px;
	top: auto;
	bottom: 10px;
	z-index: 2;
	display: flex;
	align-items: center;
	gap: 10px;
}

@keyframes skeleton-loading {
	to {
		background-position: left;
	}
}

.card {
	padding: 1rem;
	border-radius: 15px;
	position: relative;
	display: flex;
	width: 100%;
	margin: 10px 10px;
	align-items: center;
	min-height: 110px;
	background: linear-gradient(90deg, #ddda 40%, #efefefaa, #ddda 60%) right /
		300% 100%;
	animation: skeleton-loading 2.5s linear infinite;

	&:not(:last-of-type) {
		border-bottom: 1px solid $color-gray-1;
		padding-bottom: 10px;
		margin-bottom: 10px;
	}
}

.loading {
	&Col {
		height: 20px;

		&:not(:last-child) {
			margin-bottom: 10px;
		}

		&Lg {
			width: 150px;
		}

		&Sm {
			width: 90px;
		}
	}
}
