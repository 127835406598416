@import '../../../modules';
@import '../../../core/animation';

.card {
    @include respond-above(md) {
        height: 100%;
    }

    &:hover {
        cursor: pointer;

        :global(.edit-overlay) {
            animation-name: fadeIn;
        }
    }
}

.image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
    padding: 0.5rem;
    aspect-ratio: 16/9;
    animation: fadeOutTop $transition-timing * 2 cubic-bezier(0.65, 0, 0.45, 1) forwards;

    :global(.swiper-slide-active) & {
        animation: fadeInUp $transition-timing * 2 cubic-bezier(0.65, 0, 0.45, 1) forwards;
    }

    &Wrapper {
        width: 100%;
        flex-shrink: 0;
        border-radius: 20px;
        overflow: hidden;

        @include respond-above(md) {
            width: 45%;
            margin-left: -5rem;
        }
    }
}

.body {
    @include respond-above(md) {
        max-height: calc(100% - 5rem);
        padding-right: 1.5rem;
    }

    >* {
        animation-name: fadeOutTop;
        animation-fill-mode: both;
        animation-duration: $transition-timing + 0.1;


        :global(.swiper-slide-active) & {
            animation-name: fadeInUp;

            @for $i from 0 to 10 {
                &:nth-child(#{$i + 1}) {
                    animation-delay: $i * 0.1 + 0.35s;
                }
            }
        }
    }

}

.scrollbar {
    height: 100%;

    @include respond-above(md) {
        padding-right: 1rem;
        height: calc(100% - 3rem);
    }
}