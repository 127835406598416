@import '../../modules';
@import '../../core/animation';

$image-size-desktop: 10rem;
$image-size-tablet: 8.5rem;
$image-size-mobile: 7rem;

.body {
    position: relative;
    margin-bottom: 1.25rem;
    display: flex;
}

.logo {
    width: $image-size-mobile;
    height: $image-size-mobile;
    border: 1px solid $color-gray-1;
    border-radius: $border-radius-10;
    padding: 0.25rem;
    flex-shrink: 0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    // margin-bottom: 1rem;

    @include respond-above(sm) {
        // margin-right: 1.5rem;
        // margin-bottom: 0;
        width: $image-size-tablet;
        height: $image-size-tablet;
    }

    @include respond-above(md) {
        width: $image-size-desktop;
        height: $image-size-desktop;
    }

    &Box {
        overflow: hidden;
        width: 100%;
        height: 100%;
        display: block;
        border-radius: $border-radius-10;
    }

    &:hover {
        .edit {
            animation-name: fadeIn;
        }
    }
}

.shimmer {
    border-radius: $border-radius-10;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.edit {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: #fff;
    background-color: rgba($color-primary, 0.75);
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    top: 0;
    z-index: 1;
    border-radius: $border-radius-10;
    animation-name: fadeOut;
    animation-fill-mode: forwards;
    animation-duration: $transition-timing + 0.05;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 6;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: $border-radius-10;
    background-color: rgba($color-gray-5, 0.9);
}

.publish {
    @include respond-below(sm) {
        position: absolute;
        right: 0;
        top: 0;
    }
}

.meta {
    border: 1px solid rgba($color-primary-light, 0.3);
    border-radius: $border-radius-10;
    padding: 0.5rem 1rem;

    &:not(:last-of-type) {
        margin-right: 1.25rem;
    }
}

.disablePublish{
    border-radius: $border-radius-10;
    padding: 10px;
    background: rgba(255, 152, 0, 0.20);
}