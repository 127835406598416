@import '../../modules';

.box {
    // padding: 15px !important;
}

.text {
    margin-left: 10px;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.65em;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: $color-gray-5;

    @include respond-below(sm) {
        font-size: 14px;
    }
}