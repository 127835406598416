.checkmark {
	stroke-width: 3;
	stroke: currentColor;
	text-align: center;
	stroke-miterlimit: 10;
	margin: 0 auto;
	box-shadow: inset 0px 0px 0px currentColor;
}

.circle {
	stroke-dasharray: 190;
	stroke-dashoffset: -190;
	stroke-width: 3;
	stroke-miterlimit: 11;
	stroke-linecap: round;
	stroke: currentColor;
	transform: rotate(200deg);
	transform-origin: 50%;
	animation: stroke 0.55s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.check {
	transform-origin: 50%;
	transform: translate3d(10%, 10%, 0);
	stroke-dasharray: 140;
	stroke-dashoffset: 140;
	stroke-linecap: round;
	stroke-linejoin: round;
	animation: stroke 0.55s cubic-bezier(0.65, 0, 0.45, 1) 0.45s forwards;
}

@-webkit-keyframes stroke {
	100% {
		stroke-dashoffset: 0;
	}
}

@keyframes stroke {
	100% {
		stroke-dashoffset: 0;
	}
}
