@import '../../modules';

.wrapper {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	min-height: 100vh;
}

.container {
	max-width: 1600px !important;
}

.boxCompleteRegistration {
	background-color: #FFF0E4;
	border: 1px solid #FFB471;
	width: 100%;
	padding: 16px 24px 16px 24px;
	color: $color-yellow;
	display: flex;
	align-items: center;
	gap: 24px;
	justify-content: space-between;
	border-radius: 16px;
}

.content {
	margin-top: 20px;
	margin-bottom: 30px;

	@include respond-above(sm) {
		margin-right: 20px;
		margin-left: 20px;
	}

	@include respond-above(md) {
		margin: 30px;
	}

	@include respond-above(xl) {
		margin-top: 45px;
		margin-bottom: 45px;
	}

	&_wrapper {
		padding-top: $header-height-mobile;
		flex-grow: 1;
		background: $color-gray-1;
		position: relative;

		@include respond-above(md) {
			padding-top: $header-height-desktop;
			margin-left: $sidebar-width;
		}
	}
}
