.swiper-slide-thumb-active {
	img {
		border: 3px solid;
	}
}

.swiper-slide-thumb-active .boxTitleThumbs {
	background-color: #737373;
	color: #fff;
}
