@import '../../../modules';
@import '../../../core/animation';

$logo-size-mobile: 8rem;
$logo-size-desktop: 10.75rem;

.header {
	margin-top: calc(-#{$logo-size-mobile + 0.5rem} / 2);

	@include respond-above(sm) {
		margin-top: -5rem;
	}

	&Logo {
		margin-right: auto;
		margin-left: auto;
		flex-shrink: 0;
		width: $logo-size-mobile;
		height: $logo-size-mobile;
		padding: 0.75rem;
		background: #fff;
		border-radius: 30px;
		position: relative;
		box-shadow: 0px 5px 10px rgba($color-gray-5, 0.15);
		overflow: hidden;

		@include respond-above(sm) {
			padding: 1rem;
			border-radius: 40px;
			width: $logo-size-desktop;
			height: $logo-size-desktop;
		}

		&:hover {
			:global(.edit-overlay) {
				animation-name: fadeIn;
			}
		}
	}
}

.title {
	font-size: 25px !important;

	@include respond-above(md) {
		font-size: 28px !important;
	}

	@include respond-above(lg) {
		font-size: 30px !important;
	}
}

.logo {
	width: 100%;
	height: 100%;
	object-fit: contain;
	mix-blend-mode: multiply;
	margin: 0;
}

.edit {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	color: #fff;
	background-color: rgba($color-primary, 0.75);
	position: absolute;
	right: 0;
	left: 0;
	bottom: 0;
	top: 0;
	z-index: 1;
	border-radius: $border-radius-10;
	animation-name: fadeOut;
	animation-fill-mode: forwards;
	animation-duration: $transition-timing + 0.05;
}

.overlay {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 6;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 15px;
	background-color: rgba($color-gray-5, 0.9);
}

.inputEdit {
	position: relative;
	border: 2px dashed #8d8da0;
	padding: 5px;
	border-radius: 10px;
	min-height: 35px;
	display: flex;
	align-items: center;
}
.inputError {
	position: relative;
	border: 2px dashed #ff0000;
	padding: 5px;
	border-radius: 10px;
	min-height: 35px;
	display: flex;
	align-items: center;
}

.inputEdit:hover {
	border: 2px solid #8d8da0;
}

.inputError:hover {
	border: 2px solid #ff0000;
}

.switcherBox {
	position: relative;
	z-index: 121;
}

.boxSection {
	background-color: #f4f4f4;
	padding: 32px;
	border-radius: 16px;
}
