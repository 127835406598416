@import '../../modules';
@import '../../core/animation';

.card {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 15px !important;
    &:hover {
        cursor: pointer;

        .download {
            animation-name: fadeIn;
        }
    }
}

.download {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    color: #fff;
    background-color: rgba($color-primary, 0.8);
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    top: 0;
    z-index: 1;
    border-radius: $border-radius-15;
    animation-name: fadeOut;
    animation-fill-mode: forwards;
    animation-duration: $transition-timing + 0.05;
}